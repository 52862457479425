import { applyMiddleware, createStore } from "redux";
import rootReducer from "../store/reducers";
import logger from "redux-logger";
import thunkMiddleware from "redux-thunk";

const middleWares = [logger, thunkMiddleware];

const middleWare = () => {
  return createStore(rootReducer, applyMiddleware(...middleWares));
};

export default middleWare;
