import React, { useState } from "react";
import { Link } from "react-router-dom";
import Nav from "../../commons/Navbar/navbar";
import useWindowDimensions from "../../commons/WindowsWidth/dimension";
import { iframLiveUrl } from "../../Constants/url";
import "./reuseable.css";

export default function Reuseable(props) {
  const { width } = useWindowDimensions();
  const [isOffset, setOffset] = useState({ width: "100%", height: "100vh" });
  const changeWidth = () => {
    setOffset({ ...isOffset, width: "768px" });
  };

  const changeMobileWidth = () => {
    setOffset({ ...isOffset, width: "480px" });
  };

  const changeDesktopWidth = () => {
    setOffset({ ...isOffset, width: "100%" });
  };

  return (
    <div>
      {width < 769 && width > 480 ? (
        <header className="reuse-header">
          <div className="reuse-heading-cont">
            <Link to="/">
              <h2>Show Case</h2>
            </Link>
          </div>
          <div className="responsive-navbar">
            <button onClick={changeDesktopWidth}>
              <i className="fas fa-tablet"></i>
            </button>
            <button onClick={changeMobileWidth}>
              <i className="fas fa-mobile"></i>
            </button>
          </div>
        </header>
      ) : width < 481 ? (
        <div style={{ position: "relative", height: "80px" }}>
          <Nav />
        </div>
      ) : (
        <header className="reuse-header">
          <div className="reuse-heading-cont">
            <Link to="/">
              <h2>Show Case</h2>
            </Link>
          </div>
          <div className="responsive-navbar">
            <button onClick={changeDesktopWidth}>
              <i className="fas fa-desktop"></i>
            </button>
            <button onClick={changeWidth}>
              <i className="fas fa-tablet"></i>
            </button>
            <button onClick={changeMobileWidth}>
              <i className="fas fa-mobile"></i>
            </button>
          </div>
        </header>
      )}
      <iframe
        title="products"
        className="product-iframe"
        frameborder="0"
        border="0"
        src={iframLiveUrl + "/reusables"}
        style={{ width: isOffset.width, height: isOffset.height }}
      ></iframe>
    </div>
  );
}
