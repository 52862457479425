import * as actionType from "../../Constants/actiontype";

export const signupInit = () => {
  return {
    type: actionType.SIGNUP_INIT,
  };
};

export const signupSuccess = (data) => {
  return {
    type: actionType.SIGNUP_SUCCESS,
    payload: data,
  };
};

export const signupFailed = (error) => {
  return {
    type: actionType.SIGNUP_FAILED,
    payload: error,
  };
};

export const signupSnackbarOpen = () => {
  return {
    type: actionType.SIGNUP_SNACKBAR_OPEN,
  };
};

export const signupSnackbarClose = () => {
  return {
    type: actionType.SIGNUP_SNACKBAR_CLOSE,
  };
};
