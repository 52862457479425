import ProductImg from "../assets/cards/images/product-image-95.jpg";
import SecondImg from "../assets/cards/images/product-image-91.jpg";
import card14Img from "../assets/cards/images/card-14.jpg";
import card16Img from "../assets/cards/images/pic12.png";

import card1Img from "../assets/cards/images/ppc.svg";
import card2Img from "../assets/cards/images/card2_image.jpg";
import card3Img from "../assets/cards/images/card3-team-image.jpg";
import card6Img from "../assets/cards/images/card6-team-image.jpg";

import card10SeperatorImg from "../assets/cards/images/card_10_separator_img.png";
import card10BbqImg from "../assets/cards/images/card_10_barbecue_img.png";
import card19Image from "../assets/cards/images/card-19-image.jpg";
import card20Image from "../assets/cards/images/card-20-image.jpg";

export const cardValues1 = {
  cardImg: {
    src: card1Img,
    srcSet: `${card1Img} 150w, ${card1Img} 300w, ${card1Img} 1024w, ${card1Img} 100w`,
    width: "80",
    height: "80",
    loading: "lazy",
    sizes: "(max-width: 80px) 80vw, 80px",
    ref: "test",
    alt: "Card-Image",
  },
  cardTitle: {
    content: "Graphic Designing",
  },
  cardData: {
    content:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis commodo augue.",
  },
  backgroundColor: "#fff",
  fontFamily: "Open Sans, sans-serif",
  headingColor: "black",
  headingSize: "33px",
  textColor: "black",
  textSize: "12",
};

export const cardValues2 = {
  cardImage: {
    width: "300",
    height: "300",
    src: card2Img,
    alt: "Image Card",
    loading: "lazy",
    srcSet: `${card2Img} 300w, ${card2Img} 150w,${card2Img} 400w, ${card2Img} 625w`,
    sizes: "(max-width: 300px) 100vw, 300px",
  },
};

export const cardValues3 = {
  image: {
    width: "200px",
    height: "200px",
    src: card3Img,
    alt: "Designation Card",
    loading: "lazy",
    srcSet: `${card3Img} 400w, ${card3Img} 300w, ${card3Img} 150w`,
    sizes: "(max-width: 400px) 100vw, 400px",
  },
  designation: {
    name: "Micheal James  ",
    title: "Director OPS",
  },
  backgroundColor: "#fff",
  fontFamily: "Open Sans, sans-serif",
  headingColor: "black",
  headingSize: "33px",
  textColor: "black",
  textSize: "12",
};

export const cardValues4 = {
  heading: "Who Are We",
  content:
    "Lorem ipsum dolor sit amet, consec tetur adipi scing elit. Ut elit tellus, luctus nec ullam corper mattis, pulvinar. Etiam erat lectus, finibus eget commodo quis, tinci dunt eget leo. Nullam quis vulpu tate orci, ac accum san quam.",
  backgroundColor: "#fff",
  fontFamily: "Open Sans, sans-serif",
  headingColor: "black",
  headingSize: "33px",
  textColor: "black",
  textSize: "12",
};

export const cardValues5 = {
  backgroundColor: "#fff",
  fontFamily: "cursive",
  headingColor: "black",
  headingSize: "23px",
  accordionBackgroundColor: " rgb(104, 120, 214)",
  textColor: "black",
  textSize: "12",
  title: "Best Quality Designs",
  content:
    "Sed Fringilla Mauris Sit Amet Nibh. Donec Sodales Sagittis Magna. Sed Consequat, Leo Eget Bibendum, Sodales, Augue Velit Cursus Nunc, Quis Gravida Magna Mi A Libero.",
};

export const cardValues6 = {
  image: {
    width: "60",
    height: "60",
    src: card6Img,
    alt: "Customer-Image",
    loading: "lazy",
  },
  client: {
    name: "Micheal James",
    organization: "CEO, ACME INC.",
  },
  backgroundColor: "#fff",
  fontFamily: "Open Sans, sans-serif",
  headingColor: "black",
  headingSize: "23px",
  textColor: "black",
  textSize: "12",
};

export const cardValues7 = {
  counter: "150",
  counterColor: { color: "#000" },
  content: "Projects Completed",
};

export const cardValues8 = {
  selectOption: "Select Option",
  productName: "iPhone X",
  productPrice: "$499.00",
  productLink: "/",
  productImages: [
    {
      image1: ProductImg,
      width: "260",
      height: "260",
    },
    {
      image2: SecondImg,
      width: "260",
      height: "260",
    },
  ],
  backgroundColor: "#fff",
  fontFamily: "Open Sans, sans-serif",
  headingColor: "black",
  headingSize: "23px",
  textColor: "black",
  textSize: "12",
};

export const cardValues9 = {
  productImg: ProductImg,
  productName: "iPhone X",
  productPrice: "$499.00",
  productLink: "/",
  width: "324px",
  height: "104px",
  backgroundColor: "#fff",
  fontFamily: "Open Sans, sans-serif",
  headingColor: "black",
  headingSize: "23px",
  textColor: "black",
  textSize: "12",
};

export const cardValues10 = {
  cardImg: {
    image1: {
      width: "61",
      height: "61",
      src: card10BbqImg,
    },
    image2: {
      width: "178",
      height: "20",
      src: card10SeperatorImg,
    },
  },
  title: "BBQ",
  content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sapien tortor, semper vel venenatis in, cursus at leo. Suspendisse ex mauris, malesuada et mollis ut, vulputate vel ipsum.`,
  width: "100%",
  height: "100%",
  borderColor: "#967758",
  backgroundColor: "#fff",
  fontFamily: "Roboto",
  headingColor: "black",
  headingSize: "23px",
  textColor: "black",
  textSize: "12",
};

export const cardValues11 = {
  name: "Premium Cakes",
  description: "Milky Malt 2.5 pound.",
  cost: "Rs. 230",
  backgroundColor: "#fff",
  fontFamily: "Open Sans, sans-serif",
  headingColor: "black",
  headingSize: "23px",
  textColor: "#000",
  textSize: "12",
};

export const cardValues12 = {
  cardImage: "fa fa-opencart",
  cardTitle: "STRATEGIZE",
  cardContent: "There are many variations of Lorem Ipsum available.",
  iconColor: "red",
  width: "300px",
  height: "500px",
  backgroundColor: "black",
  fontFamily: "Open Sans, sans-serif",
  headingColor: "#fff",
  headingSize: "23px",
  textColor: "#fff",
  textSize: "12",
};

export const cardValues13 = {
  cardImage: "fa fa-opencart",
  cardTitle: "MARKETING",
  cardContent: `There are many variations of Lorem Ipsum available. `,
  iconColor: "red",
  width: "300px",
  height: "500px",
  backgroundColor: "black",
  fontFamily: "Open Sans, sans-serif",
  headingColor: "#fff",
  headingSize: "20px",
  textColor: "#fff",
  textSize: "12",
};

export const cardValues14 = {
  cardImg: `${card14Img}`,
  href: "#",
  width: "300px",
  height: "400px",
};

export const cardValues15 = {
  card: {
    image: "../assets/cards/images/horizontal-pic2.png",
    number: "1",
    title: "Lorem Ipsum",
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.  .",
    contentTextSize: "",
    contentTextColor: "#666",
    contentTextFamily: "teko, sans-serif",
    numberTextColor: "#ccc",
    numberTextSize: "60px",
    numberTextFamily: "teko, sans-serif",
    headerColor: "rgb(0, 0, 0)",
    headerTextSize: "25px",
    headerBackground: "none",
    headerTextFamily: "teko, sans-serif",
    buttonText: "Learn More",
    buttonTextColor: "#fff",
    buttonTextSize: "16px",
    buttonBackgroundColor: "#ff3600",
    buttonBorder: "1px solid transparent",
  },
};

export const cardValues16 = {
  cardItems: {
    image: card16Img,
  },
};

export const cardValues17 = {
  image:
    "https://i1.wp.com/jayla.beplusthemes.com/tech/wp-content/uploads/2018/08/tech-lookbook-04.jpg?fit=707%2C782&ssl=1",
  imageWidth: "285",
  imageHeight: "316",
  hoveredImage:
    "https://i1.wp.com/jayla.beplusthemes.com/tech/wp-content/uploads/2018/08/tech-lookbook-04.jpg?fit=707%2C782&ssl=1",
  hoveredImageWidth: "150",
  hoveredImageHeight: "150",
  productName: "Mackbook Pro 13",
  productPrice: "$499",
};

export const cardValues20 = {
  image: card20Image,
  heading: "Food",
  subHeading: "Fresh Food",
  headingFontColor: "#fff",
  headingFontSize: "40px",
  headingFontFamily: "abril fatface,cursive",
  headingFontWeight: "700",
  subHeadingFontColor: "#000",
  subHeadingFontSize: "16px",
  subHeadingFontFamily: "karla, sans-serif",
  subHeadingFontWeight: "400",
  date: "2020",
};

export const cardValues21 = {
  image: card19Image,
  heading: "Food",
  subHeading: "Lorem Ipsum Lorem Ipsum",
  headingFontColor: "#fff",
  headingFontSize: "12px",
  headingFontFamily: "karla, sans-serif",
  headingFontWeight: "400",
  subHeadingFontColor: "#fff",
  subHeadingFontSize: "24px",
  subHeadingFontFamily: "karla, sans-serif",
  subHeadingFontWeight: "700",
  dateFontColor: "#fff",
  dateFontSize: "16px",
  dateFontFamily: "karla, sans-serif",
  dateFontWeight: "400",
  date: "2021",
};
