import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_FROM_CART,
  CART_SNACKBAR_OPEN,
  REMOVE_SNACKBAR_OPEN,
} from "../../Constants/actiontype";

export const addToCart = (product) => (disptach, getState) => {
  var cartItems = getState().cart.cartItems;
  let alreadyExists = false;

  cartItems.forEach((x) => {
    if (x.id === product.id) {
      const elementsIndex = cartItems.findIndex(
        (element) => element.id === product.id
      );
      alreadyExists = true;
      x.quantity++;

      let newArray = [...cartItems];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        unitPrice: product.unitPrice + newArray[elementsIndex].unitPrice,
        unitPriceDiscount:
          product.unitPriceDiscount + newArray[elementsIndex].unitPriceDiscount,
      };

      cartItems = newArray;
    }
  });

  if (!alreadyExists) {
    cartItems.push({ ...product, quantity: 1 });
  }

  let sum = cartItems.reduce(function (prev, current) {
    return prev + +current.quantity;
  }, 0);

  disptach({
    type: ADD_TO_CART,
    payload: { cartItems, sum },
  });

  disptach({
    type: CART_SNACKBAR_OPEN,
    message: "Item added to cart",
  });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
  localStorage.setItem("cartTotal", sum);
};

export const removeFromCart = (product) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.filter(
    (x) => x.id !== product.id
  );

  let sum = cartItems.reduce(function (prev, current) {
    return prev + +current.quantity;
  }, 0);

  dispatch({ type: REMOVE_FROM_CART, payload: { cartItems, sum } });
  dispatch({
    type: REMOVE_SNACKBAR_OPEN,
    message: "Item removed from cart",
  });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
  localStorage.setItem("cartTotal", sum);
};

export const clearFromCart = () => (dispatch) => {
  const cartItems = [];
  const sum = 0;
  dispatch({ type: CLEAR_FROM_CART, payload: { cartItems, sum } });
  localStorage.setItem("cartItems", JSON.stringify([]));
  localStorage.setItem("cartTotal", 0);
};
