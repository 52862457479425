import React, { Component } from "react";
import image1 from "../../assets/images/image-1.png";
import "./login.css";
import { getLoginData } from "../../sources/loginsource";
import { connect } from "react-redux";
import Signup from "../Signup/signup";
import { Link } from "react-router-dom";
import { loginSnackbarClose } from "../../store/actions/loginaction";
import { bindActionCreators } from "redux";
import Nav from "../../commons/Navbar/navbar";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Snackbar from "../../commons/Snackbar/snackbar";
import Cookies from "js-cookie";
import Loader from "react-loader-spinner";
class Login extends Component {
  state = {
    email: "",
    password: "",
    forgotPasswordEmail: "",
    snackbarOpen: false,
  };

  componentDidMount() {
    const sign_in_btn = document.querySelector("#sign-in-btn");
    const sign_up_btn = document.querySelector("#sign-up-btn");
    const container = document.querySelector(".cont");

    sign_up_btn.addEventListener("click", () => {
      container.classList.add("sign-up-mode");
    });

    sign_in_btn.addEventListener("click", () => {
      container.classList.remove("sign-up-mode");
    });
  }

  handleOnSubmit = (event) => {
    event.preventDefault();
    if (this.state.email === "" || this.state.password === "") {
      this.notify("All fields are required");
    } else {
      this.props.getLoginData(this.state.email, this.state.password);
    }
    this.setState({ snackbarOpen: true });

    // if (
    //   this.props.data &&
    //   this.props.data.data.errors &&
    //   this.props.data.data.message
    // ) {
    //   this.notify(this.props.data.data.errors.email);
    //   this.notify(this.props.data.data.errors.password);
    // } else if (this.props.data && this.props.data.data.message) {
    //   this.notify(this.props.data.data.message);
    // }
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.setState({
      snackbarOpen: false,
    });
  };

  notify = (message) => {
    toast.error(message);
  };

  render() {
    if (this.props.data && this.props.data.data.accessToken) {
      let inHour = new Date(new Date().getTime() + 1 * 3600 * 1000);
      const { history } = this.props;
      Cookies.set("Token", this.props.data.data.accessToken, {
        expires: inHour,
      });
      Cookies.set("Email", this.props.data.data.email, { expires: inHour });
      Cookies.set("Id", this.props.data.data.id, { expires: inHour });
      Cookies.set("Mobile", this.props.data.data.mobile, { expires: inHour });
      Cookies.set("Name", this.props.data.data.name, { expires: inHour });
      if (this.props.status === "Success") {
        Cookies.set("Status", "LoggedIn", { expires: inHour });
        if (history) {
          history.push("/");
        }
      }
    }
    return (
      <>
        <ToastContainer
          position="bottom-right"
          autoClose={10000}
          newestOnTop={false}
          type="success"
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {this.props.data &&
        this.props.data.data.errors &&
        this.props.data.data.message ? (
          <Snackbar
            openSnackbar={this.props.snackbaropen}
            closeSnackbar={this.props.onSnackBarClick}
            snackbarmessage={
              this.props.data.data.errors.email ||
              this.props.data.data.errors.password
            }
            clickSnackbar={this.props.onSnackBarClick}
            fail={true}
          />
        ) : this.props.data && this.props.data.data.message ? (
          <Snackbar
            openSnackbar={this.props.snackbaropen}
            closeSnackbar={this.props.onSnackBarClick}
            snackbarmessage={this.props.data.data.message}
            clickSnackbar={this.props.onSnackBarClick}
            fail={true}
          />
        ) : null}
        <Nav status={this.props.status} />
        <div className="cont">
          <div className="forms-container">
            <div className="signin-signup">
              <form action="#" className="sign-in-form showcase-form">
                <h2 className="title">Sign in</h2>
                <div className="showcase-input-field">
                  <i className="fas fa-user"></i>
                  <input
                    type="text"
                    placeholder="Email *"
                    name="email"
                    autoComplete="off"
                    required
                    onChange={(event) => this.handleOnChange(event)}
                  />
                </div>

                <div className="showcase-input-field">
                  <i className="fas fa-lock"></i>
                  <input
                    type="password"
                    placeholder="Password *"
                    name="password"
                    autoComplete="off"
                    required
                    onChange={(event) => this.handleOnChange(event)}
                  />
                </div>

                {/*<div className="input-field">
                  <i className="fas fa-lock"></i>
                  <input
                    type="email"
                    placeholder="email"
                    name="forgotPasswordEmail"
                    onChange={(event) => this.handleOnChange(event)}
                  />
    </div> */}

                {/*<input
                  type="submit"
                  value="Forgot"
                  className="Forgot Password"
                  onClick={(event) => this.forgotPassword(event)}
                />*/}

                <button
                  className="log-btn solid login-button"
                  onClick={(event) => this.handleOnSubmit(event)}
                >
                  {this.props.isLoading ? (
                    <Loader type="Oval" color="#222" height={30} width={30} />
                  ) : (
                    "Login"
                  )}
                </button>

                <Link to="/forgot">Forgotten password?</Link>
              </form>
              <Signup />
            </div>
          </div>

          <div className="panels-container">
            <div className="panel left-panel">
              <div className="content">
                <h3 className="new-heading">New ?</h3>
                <p className="new-heading">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Debitis, ex ratione. Aliquid!
                </p>
                <button
                  className="log-btn transparent sign-up-btn"
                  id="sign-up-btn"
                >
                  Sign up
                </button>
              </div>
              <img src={image1} className="image" alt="" />
            </div>
            <div className="panel right-panel">
              <div className="content content-padding">
                <h3 className="new-heading">Already Signed Up ?</h3>
                <p className="new-heading">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Nostrum laboriosam ad deleniti.
                </p>
                <button
                  className="log-btn transparent sign-up-btn"
                  id="sign-in-btn"
                >
                  Sign in
                </button>
              </div>
              <img src={image1} className="image" alt="" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.login.data,
    status: state.login.status,
    snackbaropen: state.login.snackbaropen,
    snackbarmessage: state.login.snackbarmessage,
    isLoading: state.login.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLoginData: (email, password) => dispatch(getLoginData(email, password)),
    onSnackBarClick: bindActionCreators(loginSnackbarClose, dispatch),
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
