import React, { useEffect, useState } from "react";
import "./checkout.css";
import { URL } from "../../Constants/url";
import Nav from "../../commons/Navbar/navbar";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import Loader from "react-loader-spinner";
import { removeFromCart, clearFromCart } from "../../store/actions/cartaction";
import { useDispatch, useSelector } from "react-redux";
import CheckuotItem from "../CheckoutItem/checkuotItem";
import Snackbar from "../../commons/Snackbar/snackbar";
import Rodal from "rodal";

// include styles
import "rodal/lib/rodal.css";

function Checkout() {
  const localCartItems = useSelector((state) => state.cart.cartItems);
  const snackbarOpen = useSelector((state) => state.cart.snackbarOpen);
  const snackbarMessage = useSelector((state) => state.cart.snackbarMessage);
  const dispatch = useDispatch();
  let history = useHistory();
  const [form, setState] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const updateField = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (evt) => {
    const mobile = evt.target.validity.valid ? evt.target.value : form.mobile;
    setState({
      ...form,
      [evt.target.name]: mobile,
    });
  };

  const cartData = localStorage.getItem("cartItems");
  const cartItems = JSON.parse(cartData);
  const Token = Cookies.get("Token") ? Cookies.get("Token") : "";
  if (cartItems) {
    var sum = cartItems.reduce(function (prev, current) {
      return prev + +current.unitPrice;
    }, 0);

    var discountsum = cartItems.reduce(function (prev, current) {
      return prev + +current.unitPriceDiscount;
    }, 0);

    var products = cartItems.map((items) => {
      return {
        productVariantId: items.id,
        unitPrice: items.unitPrice,
        discountedPrice: items.unitPriceDiscount,
        quantity: items.quantity,
        displayName: items.displayName,
        currencyCode: items.currencyCode,
      };
    });
  }

  const Name = Cookies.get("Name");
  const Email = Cookies.get("Email");
  const Mobile = Cookies.get("Mobile");
  const Id = Cookies.get("Id");

  const checkoutApi = () => {
    setLoading(true);

    const Nameval = Name ? Name : form.name;
    const Emailval = Email ? Email : form.email;
    const Mobileval = Mobile ? Mobile : form.mobile;
    const Idval = Id ? Id : null;

    if (Nameval === "" || Emailval === "" || Mobileval === "") {
      notify("All Fields are required", "fail");
      setLoading(false);
    } else {
      fetch(`${URL}/checkout`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + Token,
        },
        body: JSON.stringify({
          customerId: Idval,
          name: Nameval,
          email: Emailval,
          mobile: Mobileval,
          products: products,
          subTotal: Token ? discountsum : sum ? sum : 0,
        }),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (parsedJson) {
          if (parsedJson.orderNumber) {
            setLoading(false);
            localStorage.setItem("OrderNumber", parsedJson.orderNumber);
            dispatch(clearFromCart());
            if (history) {
              history.push("/confirmation");
            }
          } else if (parsedJson.errors && parsedJson.message) {
            setLoading(false);
            notify(parsedJson.errors.name, "fail");
            notify(parsedJson.errors.email, "fail");
            notify(parsedJson.errors.mobile, "fail");
            notify(parsedJson.message, "fail");
          } else if (parsedJson.message) {
            notify(parsedJson.message, "fail");
          }
        })
        .catch(function (error) {
          setLoading(false);
          return error;
        });
    }
  };

  useEffect(
    () => {
      var timer1 = setTimeout(() => setOpen(true), 5000);

      // this will clear Timeout when component unmount like in willComponentUnmount
      return () => {
        clearTimeout(timer1);
      };
    },
    [] //useEffect will run only one time
    //if you pass a value to array, like this [data] than clearTimeout will run every time this value changes (useEffect re-run)
  );

  const notify = (message, status) => {
    if (status === "fail") {
      toast.error(message);
    } else {
      toast.success(message);
    }
  };

  const Hide = () => {
    setOpen(false);
  };

  return (
    <div>
      {Token ? (
        ""
      ) : (
        <Rodal visible={open} onClose={Hide} height={350}>
          <div className="view-modal-content">
            <i className="fas fa-gift"></i>
            <div>
              <p>Login or Signup to get instant discount</p>
            </div>
            <Link to="/login">
              <button className="home-explore-btn">Login</button>
            </Link>
          </div>
        </Rodal>
      )}
      <Snackbar
        openSnackbar={snackbarOpen}
        closeSnackbar={() => dispatch({ type: "REMOVE_SNACKBAR_CLOSE" })}
        snackbarmessage={snackbarMessage}
        clickSnackbar={() => dispatch({ type: "REMOVE_SNACKBAR_CLOSE" })}
      />
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={10000}
          newestOnTop={false}
          type="success"
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      <Nav navPadding={true} />
      <CheckuotItem />
      <div className="checkout-container">
        <div className="window">
          <div className="order-info">
            <div className="order-info-content">
              <h2>Order Summary</h2>
              <div className="product-height">
                {localCartItems && localCartItems.length > 0 ? (
                  localCartItems.map((items, index) => {
                    return (
                      <div key={index}>
                        <div className="line"></div>
                        <table className="order-table">
                          <tbody>
                            <tr>
                              <td className="remove-product-item">
                                <button
                                  onClick={() =>
                                    dispatch(removeFromCart(items))
                                  }
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </td>
                              <td className="product-image-td">
                                <img
                                  src="https://dl.dropboxusercontent.com/s/sim84r2xfedj99n/%24_32.JPG"
                                  className="full-width"
                                  alt="product"
                                />
                              </td>
                              <td>
                                <br />{" "}
                                <span className="thin">
                                  {items.displayName}
                                </span>
                                <span className="thin small">
                                  <br /> Quantity: {items.quantity}
                                  <br />
                                  <br />
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: "0px", padding: "0px" }}>
                                <div className="price">
                                  {Token ? (
                                    <>
                                      <div className="total-price-count">
                                        <p className="strike-through">
                                          {items.currencyCode + items.unitPrice}
                                        </p>
                                        <p>
                                          {items.currencyCode +
                                            items.unitPriceDiscount}
                                        </p>
                                      </div>
                                    </>
                                  ) : (
                                    items.currencyCode + items.unitPrice
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })
                ) : (
                  <>
                    {" "}
                    <div className="line"></div>
                    <table className="order-table">
                      <h2>No Items in cart</h2>
                    </table>{" "}
                  </>
                )}
                <div className="line"></div>
              </div>
              <div className="total">
                <span style={{ float: "left" }}>TOTAL</span>
                <span style={{ float: "right", textAlign: "right" }}>
                  ${Token ? discountsum : sum ? sum : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="credit-info">
            <div className="credit-info-content">
              <h1>Billing Details</h1>
              <span>Name</span>
              <input
                className="showcase-input-field"
                name="name"
                onChange={updateField}
                autoComplete="off"
                readOnly={Name && Name !== "undefined" ? true : false}
                value={Name && Name !== "undefined" ? Name : form.name}
              ></input>
              <span>Email</span>
              <input
                className="showcase-input-field"
                name="email"
                autoComplete="off"
                onChange={updateField}
                readOnly={Email && Email !== "undefined" ? true : false}
                value={Email && Email !== "undefined" ? Email : form.email}
              ></input>
              <span>Mobile No#</span>
              <input
                pattern="[0-9]*"
                className="showcase-input-field"
                name="mobile"
                autoComplete="off"
                onInput={handleChange}
                readOnly={Mobile && Mobile !== "undefined" ? true : false}
                value={Mobile && Mobile !== "undefined" ? Mobile : form.mobile}
              ></input>
              {/* <table class="half-input-table">
                <tr>
                  <td>
                    {" "}
                    Expires
                    <input class="input-field"></input>
                  </td>
                  <td>
                    CVC
                    <input class="input-field"></input>
                  </td>
                </tr>
  </table> */}
              <button className="pay-btn" onClick={checkoutApi}>
                {isLoading ? (
                  <Loader type="Oval" color="#222" height={30} width={30} />
                ) : (
                  "Checkout"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
