import { React, Component } from "react";
import { postSignupData } from "../../sources/signupsource";
import { connect } from "react-redux";
import { signupSnackbarClose } from "../../store/actions/signupaction";
import { bindActionCreators } from "redux";
import ResendEmail from "../VerifyUser/verifyUser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Snackbar from "../../commons/Snackbar/snackbar";
import Loader from "react-loader-spinner";
class Signup extends Component {
  state = {
    username: "",
    email: "",
    password: "",
    phoneNo: "",
    mobile: "",
  };

  handleOnSubmit = (event) => {
    event.preventDefault();

    if (
      this.state.username === "" ||
      this.state.email === "" ||
      this.state.password === ""
    ) {
      this.notify("Fields labelled with * are required");
    } else {
      this.props.postSignupData(
        this.state.username,
        this.state.email,
        this.state.mobile,
        this.state.password,
        this.state.phoneNo
      );
    }
    localStorage.setItem("VerfiyEmail", this.state.email);
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleOnChangeNumber = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ phoneNo: e.target.value });
    }
  };

  handleOnChangeNumber2 = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ mobile: e.target.value });
    }
  };

  notify = (message) => {
    toast.error(message);
  };

  render() {
    return (
      <>
        {this.props.data && this.props.data.data.errors ? (
          <div>
            <Snackbar
              openSnackbar={this.props.snackbaropen}
              closeSnackbar={this.props.onSnackBarClick}
              snackbarmessage={
                this.props.data.data.errors.name ||
                this.props.data.data.errors.email ||
                this.props.data.data.errors.password ||
                this.props.data.data.errors.mobile
              }
              clickSnackbar={this.props.onSnackBarClick}
              fail={true}
            />
          </div>
        ) : null}
        <form action="#" className="sign-up-form showcase-form">
          {this.props.data &&
          this.props.data.data.message === "Customer created successfully" ? (
            <ResendEmail />
          ) : (
            <>
              <h2 className="title">Sign up</h2>
              <div className="showcase-input-field">
                <i className="fas fa-user"></i>
                <input
                  autoComplete="off"
                  type="text"
                  placeholder="Username *"
                  name="username"
                  onChange={(event) => this.handleOnChange(event)}
                />
              </div>
              <div className="showcase-input-field">
                <i className="fas fa-envelope"></i>
                <input
                  autoComplete="off"
                  type="email"
                  placeholder="Email *"
                  name="email"
                  onChange={(event) => this.handleOnChange(event)}
                />
              </div>
              <div className="showcase-input-field">
                <i className="fas fa-lock"></i>
                <input
                  autoComplete="off"
                  type="password"
                  placeholder="Password *"
                  name="password"
                  onChange={(event) => this.handleOnChange(event)}
                />
              </div>
              <div className="showcase-input-field">
                <i className="fas fa-phone"></i>
                <input
                  autoComplete="off"
                  type="text"
                  placeholder="Phone No#"
                  name="phoneNo"
                  value={this.state.phoneNo}
                  onChange={(event) => this.handleOnChangeNumber(event)}
                />
              </div>
              <div className="showcase-input-field">
                <i className="fas fa-mobile"></i>
                <input
                  autoComplete="off"
                  type="text"
                  placeholder="Mobile"
                  name="mobile"
                  value={this.state.mobile}
                  onChange={(event) => this.handleOnChangeNumber2(event)}
                />
              </div>
              <button
                className="log-btn login-button"
                onClick={(event) => this.handleOnSubmit(event)}
              >
                {this.props.isLoading ? (
                  <Loader type="Oval" color="#222" height={30} width={30} />
                ) : (
                  "Sign Up"
                )}
              </button>
            </>
          )}
        </form>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.signup.data,
    snackbaropen: state.signup.snackbaropen,
    snackbarmessage: state.signup.snackbarmessage,
    isLoading: state.signup.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    postSignupData: (userName, email, mobile, password, phoneNo) =>
      dispatch(postSignupData(userName, email, mobile, password, phoneNo)),
    onSnackBarClick: bindActionCreators(signupSnackbarClose, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
