import React, { Component } from "react";
import "./preview.css";
import Picture1 from "../../assets/images/Showcase Template 01.png";
import Picture2 from "../../assets/images/Showcase Template 02.png";
import Picture3 from "../../assets/images/Showcase Template 03.png";
import Picture4 from "../../assets/images/Showcase Template 04.png";
import Picture5 from "../../assets/images/Showcase Wordpress Template 01.png";
import Picture6 from "../../assets/images/Showcase Wordpress Template 02.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addToCart } from "../../store/actions/cartaction";
import { connect } from "react-redux";

class Preview extends Component {
  notify = (message) => {
    toast.success(message);
  };

  render() {
    return (
      <div>
        <ToastContainer
          position="bottom-left"
          autoClose={10000}
          newestOnTop={false}
          type="success"
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="main-preview-container">
          <div className="preview-image-container">
            <img
              alt="preview"
              src={
                this.props.destination === 0
                  ? Picture1
                  : this.props.destination === 1
                  ? Picture2
                  : this.props.destination === 2
                  ? Picture3
                  : this.props.destination === 3
                  ? Picture4
                  : this.props.destination === 4
                  ? Picture5
                  : this.props.destination === 5
                  ? Picture5
                  : this.props.destination === 6
                  ? Picture6
                  : this.props.destination === 7
                  ? Picture6
                  : ""
              }
            />
          </div>
          <div className="preview-description-container">
            <div className="container">
              <div className="preview-container">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <h2>Description</h2>
                    <p>
                      {this.props.data
                        ? this.props.data.data[0].feature_details
                        : ""}
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 justify-preview-btn">
                    <div className="preview-btn">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          this.props.destination === 0
                            ? "http://demo-custom-template1.inertiasoft.net"
                            : this.props.destination === 1
                            ? "http://demo-custom-template2.inertiasoft.net"
                            : this.props.destination === 2
                            ? "http://demo-custom-template3.inertiasoft.net"
                            : this.props.destination === 3
                            ? "http://demo-custom-template4.inertiasoft.net"
                            : this.props.destination === 4
                            ? "http://demo-custom-ecommerce.inertiasoft.net"
                            : this.props.destination === 5
                            ? "http://demo-wp-template1.inertiasoft.net"
                            : this.props.destination === 6
                            ? "http://demo-wp-template2.inertiasoft.net"
                            : this.props.destination === 7
                            ? "http://showcase.inertiasoft.net"
                            : "#!"
                        }
                      >
                        <button className="button-width">Preview</button>
                      </a>

                      <button
                        className="home-preview-btn button-width"
                        onClick={
                          this.props.destination === 0
                            ? () => this.props.addCart(this.props.data.data[0])
                            : this.props.destination === 1
                            ? () => this.props.addCart(this.props.data.data[1])
                            : this.props.destination === 2
                            ? () => this.props.addCart(this.props.data.data[2])
                            : this.props.destination === 3
                            ? () => this.props.addCart(this.props.data.data[3])
                            : this.props.destination === 4
                            ? () => this.props.addCart(this.props.data.data[4])
                            : this.props.destination === 5
                            ? () => this.props.addCart(this.props.data.data[5])
                            : this.props.destination === 6
                            ? () => this.props.addCart(this.props.data.data[6])
                            : this.props.destination === 7
                            ? () => this.props.addCart(this.props.data.data[7])
                            : ""
                        }
                      >
                        Add To Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/*            <div className="cards-container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <h3>Heading</h3>
                  <p>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                </div>

                <div className="col-lg-4 col-md-6">
                  <h3>Heading</h3>
                  <p>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                </div>

                <div className="col-lg-4 col-md-6">
                  <h3>Heading</h3>
                  <p>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-container">
              <h2 className="text-center">Projects</h2>
              <Carousel>
                <Carousel.Item>
                  <img src={image1} alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={image2} alt="Third slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={image3} alt="Third slide" />
                </Carousel.Item>
              </Carousel>
            </div>
                    */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.reusablehomedata.data,
    cartItems: state.cart.cartItems,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addCart: (product) => dispatch(addToCart(product)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
