export const badgeValues = {
  description: "Friend Request",
  color: "primary",
  content: "09",
};

export const badgeValues7 = {
  description: "This is the heading",
  color: "primary",
  content: "Premium",
};

export const badgeValues8 = {
  description: "This is the heading",
  color: "primary",
  content: "Free",
};
