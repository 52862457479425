import React from "react";
import Navbar from "../../commons/Navbar/navbar";
import CheckuotItem from "../CheckoutItem/checkuotItem";
import "./confirmation.css";

export default function confirmation() {
  const OrderNumber = localStorage.getItem("OrderNumber");
  return (
    <>
      <Navbar navPadding={true} />
      <CheckuotItem />
      <div className="main-confirmation-container">
        <div className="container confirmation-container">
          <i className="far fa-check-circle"></i>
          <div className="row">
            <div class="col-md-12 text-center">
              <h1>Thank you for your order</h1>
              <h3>
                Your order number is <span>#{OrderNumber}</span>
              </h3>
              <p>
                <i>
                  The email has been received for your order. The sales team
                  will contact you within 48 hours
                </i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
