import React, { Component } from "react";
import image1 from "../../assets/images/image-1.png";
import Signup from "../Signup/signup";
import { URL } from "../../Constants/url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../commons/Navbar/navbar";
import { withRouter } from "react-router";

class Verify extends Component {
  state = {
    newPassword: "",
    confirmPassword: "",
    isLoading: false,
  };

  handleOnSubmit = (event) => {
    const { history } = this.props;

    this.setState({
      isLoading: true,
    });
    var that = this;
    event.preventDefault();
    const query = new URLSearchParams(this.props.location.search);

    const Token = query.get("token");
    event.preventDefault();
    fetch(`${URL}/email/verify?token=` + Token, {
      method: "GET",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (parsedJson) {
        that.setState({ isLoading: false });

        if (parsedJson.status === 404) {
          that.notify(parsedJson.message, "fail");
        } else {
          that.notify(parsedJson.message);
          if (history) {
            history.push("/login");
          }
        }
      })
      .catch(function (error) {
        that.setState({ isLoading: false });

        return error;
      });
  };

  notify = (message, status) => {
    if (status === "fail") {
      toast.error(message);
    } else {
      toast.success(message);
    }
  };

  render() {
    return (
      <>
        <Navbar />
        <ToastContainer
          position="bottom-right"
          autoClose={10000}
          newestOnTop={false}
          type="success"
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="cont">
          <div className="forms-container">
            <div className="signin-signup">
              <form action="#" className="sign-in-form showcase-form">
                <h2 className="title">Please Verify Your Account</h2>
                <button
                  className="resend-btn-2 solid login-button"
                  onClick={this.handleOnSubmit}
                >
                  Verify
                </button>
              </form>
              <Signup />
            </div>
          </div>

          <div className="panels-container">
            <div className="panel left-panel">
              <div className="content">
                <h3 className="new-heading">New ?</h3>
                <p className="new-heading">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Debitis, ex ratione. Aliquid!
                </p>
              </div>
              <img src={image1} className="image" alt="" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Verify);
