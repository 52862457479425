import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_FROM_CART,
  CART_SNACKBAR_CLOSE,
  CART_SNACKBAR_OPEN,
  REMOVE_SNACKBAR_OPEN,
  REMOVE_SNACKBAR_CLOSE,
} from "../../Constants/actiontype";

const initialState = {
  cartItems: JSON.parse(localStorage.getItem("cartItems")) || [],
  cartTotal: JSON.parse(localStorage.getItem("cartTotal")) || 0,
  snackbarOpen: false,
  snackbarMessage: "",
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        cartItems: action.payload.cartItems,
        cartTotal: action.payload.sum,
      };
    case REMOVE_FROM_CART:
      return {
        cartItems: action.payload.cartItems,
        cartTotal: action.payload.sum,
      };
    case CLEAR_FROM_CART:
      return {
        cartItems: action.payload.cartItems,
        cartTotal: action.payload.sum,
      };
    case CART_SNACKBAR_OPEN:
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.message,
      };
    case REMOVE_SNACKBAR_OPEN:
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.message,
      };
    case REMOVE_SNACKBAR_CLOSE:
      return {
        ...state,
        snackbarOpen: false,
      };
    case CART_SNACKBAR_CLOSE:
      return {
        ...state,
        snackbarOpen: false,
      };
    default:
      return state;
  }
}
