import axios from "axios";
import {
  signupInit,
  signupSuccess,
  signupFailed,
  signupSnackbarOpen,
} from "../store/actions/signupaction.js";
import { URL } from "../Constants/url";

export const postSignupData = (userName, email, mobile, password, phoneNo) => {
  return (dispatch) => {
    dispatch(signupInit());
    axios
      .post(`${URL}/customer`, {
        name: userName,
        email: email,
        mobile: mobile,
        password: password,
        phone_number: phoneNo,
      })
      .then((response) => {
        if (response.data.errors || response.data.message) {
          dispatch(signupSuccess());
          dispatch(signupSnackbarOpen());
        }
        dispatch(signupSuccess(response));
      })
      .catch((error) => {
        dispatch(signupFailed(error));
      });
  };
};
