import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { Snackbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  Fail: {
    background: "#D32F2F",
  },
  Success: {
    background: "#43A047",
  },
});

const SnackBar = (props) => {
  const { classes, automation, fail } = props;
  return (
    <Snackbar
      ContentProps={
        fail
          ? {
              classes: {
                root: classes.Fail,
              },
            }
          : {
              classes: {
                root: classes.Success,
              },
            }
      }
      anchorOrigin={
        automation
          ? { vertical: "bottom", horizontal: "right" }
          : { vertical: "bottom", horizontal: "right" }
      }
      open={props.openSnackbar}
      autoHideDuration={automation ? 15000 : 5000}
      onClose={props.closeSnackbar}
      message={
        <h6 id="message-id" style={{ fontSize: "16px", color: "#fff" }}>
          {props.snackbarmessage}
        </h6>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={props.clickSnackbar}
        >
          {" "}
          x{" "}
        </IconButton>,
      ]}
    />
  );
};

export default withStyles(styles)(SnackBar);
