import Logo from "../assets/logo.png";
import shoppingCart from "../assets/nav-bar/images/shopping-cart-empty-side-view.png";
import heartImage from "../assets/nav-bar/images/heart-shape-outline.png";

export const navValue1 = {
  navImgBackground: {
    src: `${Logo}`,
    href: "#",
    alt: "Background-Image",
    backgroundColor: "#333",
    fontColor: "#fff",
    imageHeight: "50px",
    imageWidth: "100px",
  },
  navigation: [
    {
      backgroundColor: "#333",
      content: "Home",
      href: "#",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      backgroundColor: "#333",
      content: "About Us",
      href: "#",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      backgroundColor: "#333",
      content: "Services",
      href: "#",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      backgroundColor: "#333",
      content: "Contact Us",
      href: "#",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
  ],
  btnReqQuote: {
    fontColor: "#fff",
    fontSize: "14px",
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "bold",
    backgroundColor: "#EE7147",
    borderColor: "#f8f8f8",
    content: "Contact Us",
    href: "contact",
  },
};

export const navValue2 = {
  companyName: "Inertia Soft",
  svgFillColor: "#FFF",
  parentDivBackgroundColor: "#001f3f",
  navigation: [
    {
      content: "Home",
      href: "#",
      svgFillColor: "#FFF",
      path: "M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z",
    },
    {
      content: "About Us",
      href: "#",
      svgFillColor: "#FFF",
      path:
        "M15,5A2,2 0 0,1 17,7V23L10,20L3,23V7C3,5.89 3.9,5 5,5H15M9,1H19A2,2 0 0,1 21,3V19L19,18.13V3H7A2,2 0 0,1 9,1Z",
    },
    {
      content: "Services",
      href: "#",
      svgFillColor: "#FFF",
      path:
        "M12 .64L8.23 3H5V5L2.97 6.29C2.39 6.64 2 7.27 2 8V18C2 19.11 2.9 20 4 20H20C21.11 20 22 19.11 22 18V8C22 7.27 21.61 6.64 21.03 6.29L19 5V3H15.77M7 5H17V9.88L12 13L7 9.88M8 6V7.5H16V6M5 7.38V8.63L4 8M19 7.38L20 8L19 8.63M8 8.5V10H16V8.5Z",
    },
    {
      content: "Contact Us",
      href: "#",
      svgFillColor: "#FFF",
      path:
        "M20,0H4V2H20V0M4,24H20V22H4V24M20,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6A2,2 0 0,0 20,4M12,6.75A2.25,2.25 0 0,1 14.25,9A2.25,2.25 0 0,1 12,11.25A2.25,2.25 0 0,1 9.75,9A2.25,2.25 0 0,1 12,6.75M17,17H7V15.5C7,13.83 10.33,13 12,13C13.67,13 17,13.83 17,15.5V17Z",
    },
  ],
  btnReqQuote: {
    content: "Request Quote",
  },
};

export const navValue3 = {
  navImgBackground: {
    src: `${Logo}`,
    href: "#",
    alt: "Background-Image",
    backgroundColor: "#0074D9",
    imageHeight: "50px",
    imageWidth: "100px",
  },
  navigation: [
    {
      content: "Home",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "About Us",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "Services",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "Contact Us",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
  ],
};

export const navValue4 = {
  companyName: "Inertia Soft",
  navImgBackground: {
    src: `${Logo}`,
    href: "http://inertiasoft.net",
    imageHeight: "50px",
    imageWidth: "100px",
    alt: "Background-Image",
    backgroundColor: "rgba(0,0,0,1)",
  },
  navigation: [
    {
      content: "Home",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "About Us",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "Services",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "Contact Us",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
  ],
};

export const navValue5 = {
  companyName: "Inertia Soft",
  navImgBackground: {
    src: `${Logo}`,
    href: "http://inertiasoft.net",
    imageHeight: "50px",
    imageWidth: "100px",
    alt: "Background-Image",
    backgroundColor: "#AAAAAA",
  },
  navigation: [
    {
      content: "Home",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "About Us",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "Services",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "Contact Us",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
  ],
};

export const navValue6 = {
  companyName: "Inertia Soft",
  navImgBackground: {
    src: `${Logo}`,
    href: "http://inertiasoft.net",
    imageHeight: "50px",
    imageWidth: "100px",
    alt: "Background-Image",
    backgroundColor: "#B10DC9",
  },
  navigation: [
    {
      content: "Home",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "About Us",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "Services",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "Contact Us",
      href: "#",
      fontColor: "#FFFFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
  ],
  socialMedia: [
    {
      link: "facebook",
      icon: "fa fa-facebook-f",
      fontColor: "#001f3f",
      fontSize: "24px",
    },
    {
      link: "twitter",
      icon: "fa fa-twitter",
      fontColor: "#001f3f",
      fontSize: "24px",
    },
    {
      link: "instagram",
      icon: "fa fa-instagram",
      fontColor: "#001f3f",
      fontSize: "24px",
    },
  ],
};

export const navValue7 = {
  companyName: "Inertia Soft",
  navImgBackground: {
    src: `${Logo}`,
    href: "http://inertiasoft.net",
    imageHeight: "50px",
    imageWidth: "100px",
    alt: "Background-Image",
    backgroundColor: "#FF851B",
  },
  navigation: [
    {
      content: "Home",
      href: "#",
      fontColor: "#333",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "About Us",
      href: "#",
      fontColor: "#333",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "Menu & Ordering",
      href: "#",
      fontColor: "#333",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "Gallery",
      href: "#",
      fontColor: "#333",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "Discounts & Offers",
      href: "#",
      fontColor: "#333",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "Careers",
      href: "#",
      fontColor: "#333",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    {
      content: "Contact Us",
      href: "#",
      fontColor: "#333",
      fontSize: "14px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
  ],
};

export const navValue8 = {
  logo: {
    image: "https://i.gifer.com/V6D.gif",
    href: "#",
  },
  navigation: {
    leftNav: [
      {
        content: "Home",
        href: "#",
        fontColor: "#333",
        fontSize: "14px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
      },
      {
        content: "About",
        href: "#",
        fontColor: "#333",
        fontSize: "14px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
      },
      {
        content: "Careers",
        href: "#",
        fontColor: "#333",
        fontSize: "14px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
      },
    ],
    rightNav: [
      {
        content: "Contact",
        href: "#",
        fontColor: "#333",
        fontSize: "14px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
      },
      {
        content: "Blogs",
        href: "#",
        fontColor: "#333",
        fontSize: "14px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
      },
      {
        content: "Introduction",
        href: "#",
        fontColor: "#333",
        fontSize: "14px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
      },
    ],
  },
  navigationColor: "black",
  navigationPosition: "relative",
  navItemColor: "transparent",
  navItemColorHover: "rgba(0,0,0,0.1)",
  color: "white",
  fontSize: "14px",
  fontFamily: "'Open Sans', sans-serif",
  fontWeight: "bold",
};

export const navValues9 = {
  header: {
    logo: `${Logo}`,
    searchIcon: "../assets/images/magnifying-search-lenses-tool.png",
    browseCategoryIcon: "/assets/images/right-menu-bars.png",
    shoppingDetails: {
      title: "My Cart",
      favouriteIcon: `${heartImage}`,
      cartIcon: `${shoppingCart}`,
      href: "http://inertiasoft.net",
      cart: {
        itemQty: 5,
        marginRight: "5px",
        fontWeight: "bolder",
        fontSize: "larger",
        fontFamily: "emoji",
        fontColor: "#fff",
      },
    },
  },
  navBarBackgroundColor: "#333",
  leftNavItems: [
    {
      href: "/",
      name: "Home",
      fontSize: "15px",
      fontColor: "#fff",
      fontFamily:
        "Circular Std Book', Helvetica, Arial, 'Lucida Grande',sans-serif",
      fontWeight: "600",
      textDecoration: "none",
      subItems: [],
    },
    {
      href: "/shop",
      name: "Shop",
      fontSize: "15px",
      fontColor: "#fff",
      fontFamily:
        "Circular Std Book', Helvetica, Arial, 'Lucida Grande',sans-serif",
      fontWeight: "600",
      textDecoration: "none",
      subItems: [],
    },
    {
      href: "/about",
      name: "About Us",
      fontSize: "15px",
      fontColor: "#fff",
      fontFamily:
        "Circular Std Book', Helvetica, Arial, 'Lucida Grande',sans-serif",
      fontWeight: "600",
      textDecoration: "none",
      subItems: [],
    },
    {
      href: "/contact",
      name: "Contact Us",
      fontSize: "15px",
      fontColor: "#fff",
      fontFamily:
        "Circular Std Book', Helvetica, Arial, 'Lucida Grande',sans-serif",
      fontWeight: "600",
      textDecoration: "none",
      subItems: [],
    },
  ],
};
