import * as actionType from "../../Constants/actiontype";

const initialState = {
  data: null,
  snackbaropen: false,
  snackbarmessage: "",
};

export default function signupreducer(state = initialState, action) {
  switch (action.type) {
    case actionType.SIGNUP_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case actionType.SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case actionType.SIGNUP_FAILED:
      return {
        ...state,
        isLoading: false,
        data: action.error,
      };
    case actionType.SIGNUP_SNACKBAR_OPEN:
      return {
        ...state,
        snackbaropen: true,
        snackbarmessage: "Username or Password is incorrect",
      };
    case actionType.SIGNUP_SNACKBAR_CLOSE:
      return {
        ...state,
        snackbaropen: false,
      };
    default:
      return state;
  }
}
