import React, { Component } from "react";
import "./nav.css";
import { Expo, TweenMax, TimelineMax, Power4 } from "gsap";
import Preview from "../../components/Preview/preview";

var t1 = new TimelineMax();

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.state = { isScrolling: false, isOpen: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    TweenMax.to(".menu-cont", 1, {
      opacity: 0,
    });

    t1.to(".bg_block_first", 0.5, {
      height: "50%",
    });
    t1.to(".bg_block_second", 0.5, {
      height: "50%",
      delay: "-0.5",
    });
    t1.to(".one", 0.3, {
      y: 9,
      autoAlpha: 0,
      delay: "-1",
      ease: Expo.easeInOut,
    });
    t1.to(".two", 0.3, {
      ease: Expo.easeInOut,
      delay: "-1",
    });
    t1.to(".tre", 0.3, {
      y: -9,
      autoAlpha: 0,
      ease: Expo.easeInOut,
      delay: "-1",
    });

    t1.to(".menu-cont", 1, {
      autoAlpha: 1,
      ease: Expo.easeOut,
      delay: "-0.3",
    });

    t1.from(
      ".menu-cont ul li",
      0.3,
      {
        scale: "1.4",
        opacity: 0,
        ease: Power4.easeInOut,
        delay: "0.8",
      },
      "-0.01"
    );

    t1.reverse();

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  toggleMenu = () => {
    t1.reversed(!t1.reversed());
    this.setState({ isScrolling: !this.state.isScrolling });

    setTimeout(
      function () {
        this.setState({ isOpen: !this.state.isOpen });
      }.bind(this),
      1500
    );
    window.fullpage_api.setAllowScrolling(this.state.isScrolling);
  };

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return (
      <>
        <div className="">
          <div
            className={
              this.state.isOpen ? "hide-element" : "nav-burger c-magnetic"
            }
          >
            <div className="span">
              <div className="home-button-container">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    this.props.destination === 0
                      ? "http://demo-custom-template1.inertiasoft.net"
                      : this.props.destination === 1
                      ? "http://demo-custom-template2.inertiasoft.net"
                      : this.props.destination === 2
                      ? "http://demo-custom-template3.inertiasoft.net"
                      : this.props.destination === 3
                      ? "http://demo-custom-template4.inertiasoft.net"
                      : this.props.destination === 4
                      ? "http://demo-custom-ecommerce.inertiasoft.net"
                      : this.props.destination === 5
                      ? "http://demo-wp-template1.inertiasoft.net"
                      : this.props.destination === 6
                      ? "http://demo-wp-template2.inertiasoft.net"
                      : this.props.destination === 6
                      ? "http://showcase.inertiasoft.net"
                      : "!#"
                  }
                >
                  <button className="home-explore-btn">Preview</button>
                </a>
                <button
                  className={this.state.isOpen ? "fix-top" : "home-preview-btn"}
                  onClick={this.toggleMenu}
                >
                  {this.state.isOpen ? "X" : "Explore"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg_block_first"></div>
        <div className="bg_block_second"></div>

        <div className="manu-container">
          <div className="menu-cont">
            <div
              className="FixedHeightContainer"
              style={{ height: this.state.height }}
            >
              <div className="Content">
                <Preview
                  destination={this.props.destination}
                  data={this.props.data}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Nav;
