import * as actionType from "../../Constants/actiontype";

const initialState = {
  data: null,
};

export default function reusabledatareducer(state = initialState, action) {
  switch (action.type) {
    case actionType.REUSABLES_DATA_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case actionType.REUSABLES_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case actionType.REUSABLES_DATA_FAILED:
      return {
        ...state,
        data: action.error,
      };
    default:
      return state;
  }
}
