import { combineReducers } from "redux";
import reusablereducer from "../reducers/reusablereducer";
import reuseabledatareducer from "../reducers/reuseabledatareducer";
import loginreducer from "../reducers/loginreducer";
import signupreducer from "../reducers/signupreducer";
import cartReducer from "../reducers/cartreducer";
import reusablehomedatareducer from "../reducers/reusablehomedatareducer";

const rootReducer = combineReducers({
  reusable: reusablereducer,
  reuseabledata: reuseabledatareducer,
  reusablehomedata: reusablehomedatareducer,
  login: loginreducer,
  signup: signupreducer,
  cart: cartReducer,
});

export default rootReducer;
