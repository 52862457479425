import React, { Component } from "react";
import "./landingPage.css";
import CurveLine2 from "../../assets/images/Curve Line 03-01.png";
import Facebook from "../../assets/images/facebook.png";
import LinkedIn from "../../assets/images/linkedin.png";
import video from "../../assets/Videos/Ad-01-Music02-Beat-(720p)-C.mp4";
import Nav from "../../commons/Navbar/navbar";
import CurveRight from "../../assets/images/Curve-Line-02-Right.png";
import CheckuotItem from "../CheckoutItem/checkuotItem";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  render() {
    return (
      <div>
        <Nav navPadding={true} />
        <CheckuotItem />
        <div className="banner" style={{ height: this.state.height }}>
          <video
            autoPlay
            loop
            muted
            playsInline
            autobuffer="true"
            src={video}
            type="video/mp4"
          ></video>
        </div>

        <div className="introduction-section">
          <div className="curve-image-container-intro">
            <img src={CurveLine2} alt="curve-line" />
          </div>
          <div className="inner-content">
            <div className="inner-introduction">
              <h2 className="gradient-text">Introduction</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
              </p>
              <div className="inner-introduction-image"></div>
            </div>
          </div>
        </div>

        <div className="summary-section">
          <div className="curve-image-container2">
            <img src={CurveRight} alt="curve-line" />
          </div>
          <div className="inner-summary-content">
            <h2>Summary</h2>
            <p>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum
            </p>
          </div>
        </div>

        <div className="introduction-section">
          <div className="curve-image-container">
            <img src={CurveLine2} alt="curve-line" />
          </div>

          <div className="curve-image-container3">
            <img src={CurveRight} alt="curve-line" />
          </div>
          <div className="inner-content">
            <div className="inner-introduction">
              <h2 className="gradient-text">Comparison</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
              </p>
              <div className="inner-introduction-image"></div>
            </div>
          </div>
        </div>

        <div className="follow-us-section">
          <h2>Follow Us</h2>
          <div className="footer-icons">
            <img className="Facebook" src={Facebook} alt="Facebook Icon" />
            <img src={LinkedIn} alt="LinkedIn Icon" />
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
