import * as actionType from "../../Constants/actiontype";

export const loginInit = () => {
  return {
    type: actionType.LOGIN_INIT,
  };
};

export const loginSuccess = (data, status) => {
  return {
    type: actionType.LOGIN_SUCCESS,
    payload: data,
    status: status,
  };
};

export const loginFailed = (error) => {
  return {
    type: actionType.LOGIN_FAILED,
    payload: error,
  };
};

export const loginSnackbarOpen = () => {
  return {
    type: actionType.LOGIN_SNACKBAR_OPEN,
  };
};

export const loginSnackbarClose = () => {
  return {
    type: actionType.LOGIN_SNACKBAR_CLOSE,
  };
};

export const signOut = () => {
  return {
    type: actionType.SIGNOUT,
  };
};
