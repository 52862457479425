import React from "react";
import "./contactDetails.css";

export default function ContactDetails() {
  return (
    <div>
      <div className="home-contact-continer">
        <div className="facebook-cont">
          <p>/inertiasoftpvtltd</p>
          <div className="f-logo">
            <i className="fab fa-facebook-f"></i>
          </div>
        </div>
        <div className="facebook-cont">
          <p>+922134961138</p>
          <div className="f-logo">
            <i className="fas fa-phone"></i>
          </div>
        </div>
        <div className="main-email-cont">
          <p>info@inertiasoft.net</p>
          <div className="f-logo">
            <i className="fas fa-envelope"></i>
          </div>
        </div>
        {/*<div className="phone-cont">
          <p>+92-213-4961138</p>
          <div className="p-logo">
            <i class="fas fa-phone"></i>
          </div>
        </div>}
        {<div className="email-cont">
          <p>info@inertiasoft.net</p>
          <div className="e-logo">
            <i class="fas fa-envelope"></i>
          </div>
  </div>*/}
      </div>
    </div>
  );
}
