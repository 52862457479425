import axios from "axios";
import {
  getReusableInit,
  getReusableDataSuccess,
  getReusableDataFailed,
} from "../store/actions/reusableaction.js";
import { URL } from "../Constants/url";

export const getReusableData = () => {
  return (dispatch) => {
    dispatch(getReusableInit());
    axios
      .get(`${URL}/products`)
      .then((response) => {
        dispatch(getReusableDataSuccess(response));
      })
      .catch((error) => {
        dispatch(getReusableDataFailed(error));
      });
  };
};
