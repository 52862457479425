import axios from "axios";
import { URL } from "../Constants/url";
import {
  loginInit,
  loginSuccess,
  loginFailed,
  loginSnackbarOpen,
} from "../store/actions/loginaction.js";

export const getLoginData = (email, password) => {
  return (dispatch) => {
    dispatch(loginInit());
    axios
      .post(`${URL}/login`, {
        email: email,
        password: password,
        device: "Web",
      })
      .then((response) => {
        if (response.data.errors || response.data.message) {
          dispatch(loginSuccess(response, "Failed"));
          dispatch(loginSnackbarOpen());
        } else {
          dispatch(loginSuccess(response, "Success"));
        }
      })
      .catch((error) => {
        dispatch(loginFailed(error));
      });
  };
};

// {
//   "message": "Invalid request body params",
//   "errors": {
//     "email": "\"email\" is not allowed to be empty"
//   }
// }

// {"message":"success"}
