import "../Home/home.css";
import React from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import "./componentPreview.css";
import ScrollNav from "../../commons/ScrollNav/scrollnav";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class CompoenntPreview extends React.Component {
  state = {
    slideIndex: 0,
    data: [],
    quantity: 0,
  };
  onLeave(origin, destination, direction) {}
  afterLoad(origin, destination, direction) {
    this.setState({ slideIndex: destination.index + 1 });
  }
  moveDown = () => {
    window.fullpage_api.moveSectionDown();
  };

  moveUp = () => {
    window.fullpage_api.moveSectionUp();
  };

  addToCart = (data) => {
    this.notify("Item added to cart");
    const found = this.state.data.some((el) => el.id === data.id);
    if (this.state.data.length > 0 && found === true) {
      const elementsIndex = this.state.data.findIndex(
        (element) => element.id === data.id
      );
      let newArray = [...this.state.data];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        quantity: newArray[elementsIndex].quantity + 1,
        unitPrice:
          (newArray[elementsIndex].quantity + 1) *
          newArray[elementsIndex].unitPrice,
        unitPriceDiscount:
          (newArray[elementsIndex].quantity + 1) *
          newArray[elementsIndex].unitPriceDiscount,
      };
      this.setState({
        data: newArray,
      });
    }

    if (this.state.data.length === 0 || found === false) {
      var qty = { quantity: 1 };

      data = { ...data, ...qty };
      this.setState({ data: [...this.state.data, data] });
    }

    const cartData = localStorage.getItem("cartItems");
    const cartItems = JSON.parse(cartData);
    let sum = cartItems.reduce(function (prev, current) {
      return prev + +current.quantity;
    }, 0);
    this.setState({ quantity: sum });
  };

  notify = (message) => {
    toast.success(message);
  };

  render() {
    localStorage.setItem("cart", JSON.stringify(this.state.data));
    var slidesNumber = document.querySelectorAll(".fp-section").length;
    return (
      <>
        <ToastContainer
          position="bottom-right"
          autoClose={10000}
          newestOnTop={false}
          type="success"
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ScrollNav />
        <div className="c-scroll-icon">
          <p>SCROLL</p>
          <div className="c-scroll-icon-line-mask">
            <div className="c-scroll-icon-line" />
          </div>
          <div className="c-scroll-icon-triangle">
            <div className="c-scroll-icon-triangle-mask first">
              <div className="c-scroll-icon-triangle-line first" />
            </div>
            <div className="c-scroll-icon-triangle-mask right">
              <div className="c-scroll-icon-triangle-line right" />
            </div>
            <div className="c-scroll-icon-triangle-mask left">
              <div className="c-scroll-icon-triangle-line left" />
            </div>
            <div className="c-scroll-icon-triangle-mask last">
              <div className="c-scroll-icon-triangle-line last" />
            </div>
          </div>
        </div>
        <div className="component-preview-container">
          <div className="up-down-btn-cont">
            <button className="up-btn" onClick={this.moveUp}>
              <i className="fas fa-chevron-up"></i>
            </button>
            <div className="slides-number-counter">
              <span>{this.state.slideIndex}</span> <span>/</span>{" "}
              <span>{slidesNumber}</span>
            </div>
            <button className="down-btn" onClick={this.moveDown}>
              <i className="fas fa-chevron-down"></i>
            </button>
          </div>
          <button style={{ zIndex: 9999 }}>
            <Link to="/checkout">Checkout</Link>
          </button>
          <ReactFullpage
            onLeave={this.onLeave.bind(this)}
            afterLoad={this.afterLoad.bind(this)}
            render={({ state, fullpageApi }) => {
              return (
                <>
                  <div id="fullpage-wrapper">
                    {this.props.data ? (
                      this.props.data.data.map((items, index) => {
                        const { name } = items;
                        return (
                          <div className="section" key={index}>
                            <div className="container">
                              <div className="heading-button-container ">
                                <div className="head-cont">
                                  <h2>{name}</h2>
                                </div>
                                <div className="basket-preview-container">
                                  <button
                                    onClick={this.addToCart.bind(this, items)}
                                  >
                                    Basket
                                  </button>
                                  <Link to="/preview">
                                    <button>Preview</button>
                                  </Link>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <p>
                                    {" "}
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip
                                    ex ea commodo consequat. Duis aute irure
                                    dolor in reprehenderit in voluptate velit
                                    esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt
                                    mollit anim id est laborum
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="section">Loading</div>
                    )}
                  </div>
                </>
              );
            }}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.reuseabledata.data,
  };
}

export default connect(mapStateToProps)(CompoenntPreview);
