import React from "react";
import {
  ReUsableNavbar1,
  ReUsableNavbar2,
  ReUsableNavbar3,
  ReUsableNavbar4,
  ReUsableNavbar5,
  ReUsableNavbar6,
  ReUsableNavbar7,
  ReUsableNavbar8,
  ReUsableNavbar9,
} from "@inertiasoft/re-usable-nav-bars";

import {
  ReUsableCard1,
  ReUsableCard2,
  ReUsableCard3,
  ReUsableCard4,
  ReUsableCard5,
  ReUsableCard6,
  ReUsableCard7,
  ReUsableCard8,
  ReUsableCard9,
  ReUsableCard10,
  ReUsableCard11,
  ReUsableCard12,
  ReUsableCard13,
  ReUsableCard14,
  ReUsableCard15,
  ReUsableCard16,
  ReUsableCard17,
  ReUsableCard20,
  ReUsableCard21,
} from "@inertiasoft/re-usable-cards";

import {
  ReUsableFooter1,
  ReUsableFooter2,
  ReUsableFooter3,
  ReUsableFooter4,
  ReUsableFooter5,
  ReUsableFooter7,
  ReUsableFooter8,
  ReUsableFooter9,
  ReUsableFooter10,
  ReUsableFooter11,
} from "@inertiasoft/re-usable-footers";

import {
  ReUsableContactUsForm1,
  ReUsableContactUsForm2,
  ReUsableContactUsForm3,
  ReUsableContactUsForm4,
  ReUsableContactUsForm5,
  ReUsableContactUsForm7,
  ReUsableContactUsForm8,
  ReUsableContactUsForm9,
} from "@inertiasoft/re-usable-contactus-forms";

import {
  ReUsableCareerForm1,
  ReUsableCareerForm2,
  ReUsableCareerForm3,
} from "@inertiasoft/re-usable-career-forms";

import {
  ReUsableProgressBar1,
  ReUsableProgressBar2,
  ReUsableProgressBar3,
  ReUsableProgressBar4,
  ReUsableProgressBar5,
  ReUsableProgressBar7,
} from "@inertiasoft/re-usable-progress-bars";

import {
  ReUsableSidebar1,
  ReUsableSidebar2,
  ReUsableSidebar3,
  ReUsableSidebar4,
  ReUsableSidebar5,
  ReUsableSidebar6,
  ReUsableSidebar7,
} from "@inertiasoft/re-usable-side-bars";

import { ReUsableCheckoutForm1 } from "@inertiasoft/re-usable-checkout-forms";

import {
  ReUsableBreadCrumb1,
  ReUsableBreadCrumb2,
  ReUsableBreadCrumb3,
  ReUsableBreadCrumb4,
  ReUsableBreadCrumb5,
  ReUsableBreadCrumb6,
  ReUsableBreadCrumb7,
  ReUsableBreadCrumb8,
  ReUsableBreadCrumb9,
  ReUsableBreadCrumb10,
  ReUsableBreadCrumb11,
  ReUsableBreadCrumb12,
} from "@inertiasoft/re-usable-bread-crumbs";

import {
  ReUsableBadge1,
  ReUsableBadge2,
  ReUsableBadge3,
  ReUsableBadge4,
  ReUsableBadge5,
  ReUsableBadge6,
  ReUsableBadge7,
  ReUsableBadge8,
} from "@inertiasoft/re-usable-badges";

import {
  ReUsableSlider1,
  ReUsableSlider2,
  ReUsableSlider3,
  ReUsableSlider4,
  // ReUsableSlider5,
  ReUsableSlider6,
  ReUsableSlider7,
  ReUsableSlider8,
  ReUsableSlider9,
  ReUsableSlider10,
} from "@inertiasoft/re-usable-sliders";

import { ReUsableSlider5 } from "@inertiasoft/re-usable-slider-5";

import {
  navValue1,
  navValue2,
  navValue3,
  navValue4,
  navValue5,
  navValue6,
  navValue7,
  navValue8,
  navValues9,
} from "../../Constants/navbar";

import {
  cardValues1,
  cardValues2,
  cardValues3,
  cardValues4,
  cardValues5,
  cardValues6,
  cardValues7,
  cardValues8,
  cardValues9,
  cardValues10,
  cardValues11,
  cardValues12,
  cardValues13,
  cardValues14,
  cardValues15,
  cardValues16,
  cardValues17,
  cardValues20,
  cardValues21,
} from "../../Constants/card";

import {
  footerValues1,
  footerValues2,
  footerValues3,
  footerValues4,
  footerValues5,
  footerValues7,
  footerValues8,
  footerValues9,
  footerValues10,
  footerValues11,
} from "../../Constants/footer";

import {
  contactValues1,
  contactValues2,
  contactValues3,
  contactValues4,
  contactValues5,
  contactValues7,
  contactValues8,
  contactValues9,
} from "../../Constants/contact";

import { checkoutValues1 } from "../../Constants/checkout";

import {
  breadcrumbValue1,
  breadcrumbValue2,
  breadcrumbValue3,
  breadcrumbValue4,
  breadcrumbValue5,
  breadcrumbValue6,
  breadcrumbValue7,
  breadcrumbValue8,
  breadcrumbValue9,
  breadcrumbValue10,
  breadcrumbValue11,
  breadcrumbValue12,
} from "../../Constants/breadcrumb";

import { badgeValues, badgeValues7, badgeValues8 } from "../../Constants/badge";

import {
  sliderValue1,
  sliderValue2,
  sliderValue3,
  sliderValue4,
  sliderValue5,
  sliderValue6,
  sliderValue7,
  sliderValue8,
  sliderValue9,
  sliderValue10,
} from "../../Constants/slider";

import {
  sidebarValues1,
  sidebarValues2,
  sidebarValues3,
  sidebarValues4,
  sidebarValues5,
  sidebarValues6,
  sidebarValues7,
} from "../../Constants/sidebar";

import {
  progressValues1,
  progressValues2,
  progressValues3,
  progressValues4,
  progressValues5,
  progressValues7,
} from "../../Constants/progress";

import {
  careerValues1,
  careerValues2,
  careerValues3,
} from "../../Constants/career";

import "./reusableComponents.css";

export default function ReusableComponents() {
  const ProductName = localStorage.getItem("ProductName");
  let container;
  if (ProductName === "nav-bar-1") {
    container = (
      <div className="App" style={{ height: "150px" }}>
        <ReUsableNavbar1 data={navValue1} />
      </div>
    );
  } else if (ProductName === "nav-bar-2") {
    container = (
      <div className="App" style={{ height: "150px" }}>
        <ReUsableNavbar2 data={navValue2} />
      </div>
    );
  } else if (ProductName === "nav-bar-3") {
    container = (
      <div className="App" style={{ height: "150px" }}>
        <ReUsableNavbar3 data={navValue3} />
      </div>
    );
  } else if (ProductName === "nav-bar-4") {
    container = (
      <div className="App" style={{ height: "150px" }}>
        <ReUsableNavbar4 data={navValue4} />
      </div>
    );
  } else if (ProductName === "nav-bar-5") {
    container = (
      <div className="App" style={{ height: "150px" }}>
        <ReUsableNavbar5 data={navValue5} />
      </div>
    );
  } else if (ProductName === "nav-bar-6") {
    container = (
      <div className="App" style={{ height: "150px" }}>
        <ReUsableNavbar6 data={navValue6} />
      </div>
    );
  } else if (ProductName === "nav-bar-7") {
    container = (
      <div className="App" style={{ height: "150px" }}>
        <ReUsableNavbar7 data={navValue7} />
      </div>
    );
  } else if (ProductName === "nav-bar-8") {
    container = (
      <div className="App" style={{ height: "150px" }}>
        <ReUsableNavbar8 data={navValue8} />
      </div>
    );
  } else if (ProductName === "nav-bar-9") {
    container = (
      <div className="App" style={{ height: "150px" }}>
        <ReUsableNavbar9 data={navValues9} />
      </div>
    );
  }

  if (ProductName === "card-1") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard1 data={cardValues1} />
            <ReUsableCard1 data={cardValues1} />
            <ReUsableCard1 data={cardValues1} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-2") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard2 data={cardValues2} />
            <ReUsableCard2 data={cardValues2} />
            <ReUsableCard2 data={cardValues2} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-3") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard3 data={cardValues3} />
            <ReUsableCard3 data={cardValues3} />
            <ReUsableCard3 data={cardValues3} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-4") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard4 data={cardValues4} />
            <ReUsableCard4 data={cardValues4} />
            <ReUsableCard4 data={cardValues4} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-5") {
    container = <ReUsableCard5 data={cardValues5} />;
  } else if (ProductName === "card-6") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard6 data={cardValues6} />
            <ReUsableCard6 data={cardValues6} />
            <ReUsableCard6 data={cardValues6} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-7") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard7 data={cardValues7} />
            <ReUsableCard7 data={cardValues7} />
            <ReUsableCard7 data={cardValues7} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-8") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard8 data={cardValues8} />
            <ReUsableCard8 data={cardValues8} />
            <ReUsableCard8 data={cardValues8} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-9") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard9 data={cardValues9} />
            <ReUsableCard9 data={cardValues9} />
            <ReUsableCard9 data={cardValues9} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-10") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard10 data={cardValues10} />
            <ReUsableCard10 data={cardValues10} />
            <ReUsableCard10 data={cardValues10} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-11") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard11 data={cardValues11} />
            <ReUsableCard11 data={cardValues11} />
            <ReUsableCard11 data={cardValues11} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-12") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard12 data={cardValues12} />
            <ReUsableCard12 data={cardValues12} />
            <ReUsableCard12 data={cardValues12} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-13") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard13 data={cardValues13} />
            <ReUsableCard13 data={cardValues13} />
            <ReUsableCard13 data={cardValues13} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-14") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard14 data={cardValues14} />
            <ReUsableCard14 data={cardValues14} />
            <ReUsableCard14 data={cardValues14} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-15") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard15 data={cardValues15} />
            <ReUsableCard15 data={cardValues15} />
            <ReUsableCard15 data={cardValues15} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-16") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard16 data={cardValues16} />
            <ReUsableCard16 data={cardValues16} />
            <ReUsableCard16 data={cardValues16} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-17") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard17 data={cardValues17} />
            <ReUsableCard17 data={cardValues17} />
            <ReUsableCard17 data={cardValues17} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-20") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard20 data={cardValues20} />
            <ReUsableCard20 data={cardValues20} />
            <ReUsableCard20 data={cardValues20} />
          </div>
        </div>
      </>
    );
  } else if (ProductName === "card-21") {
    container = (
      <>
        <div className="main-reusable-cards">
          <div className="reusable-cards">
            <ReUsableCard21 data={cardValues21} />
            <ReUsableCard21 data={cardValues21} />
            <ReUsableCard21 data={cardValues21} />
          </div>
        </div>
      </>
    );
  }

  if (ProductName === "footer-1") {
    container = <ReUsableFooter1 data={footerValues1} />;
  } else if (ProductName === "footer-2") {
    container = <ReUsableFooter2 data={footerValues2} />;
  } else if (ProductName === "footer-3") {
    container = <ReUsableFooter3 data={footerValues3} />;
  } else if (ProductName === "footer-4") {
    container = <ReUsableFooter4 data={footerValues4} />;
  } else if (ProductName === "footer-5") {
    container = <ReUsableFooter5 data={footerValues5} />;
  } else if (ProductName === "footer-7") {
    container = <ReUsableFooter7 data={footerValues7} />;
  } else if (ProductName === "footer-8") {
    container = <ReUsableFooter8 data={footerValues8} />;
  } else if (ProductName === "footer-9") {
    container = <ReUsableFooter9 data={footerValues9} />;
  } else if (ProductName === "footer-10") {
    container = <ReUsableFooter10 data={footerValues10} />;
  } else if (ProductName === "footer-11") {
    container = <ReUsableFooter11 data={footerValues11} />;
  }

  if (ProductName === "contact-us-form-1") {
    container = <ReUsableContactUsForm1 data={contactValues1} />;
  } else if (ProductName === "contact-us-form-2") {
    container = <ReUsableContactUsForm2 data={contactValues2} />;
  } else if (ProductName === "contact-us-form-3") {
    container = <ReUsableContactUsForm3 data={contactValues3} />;
  } else if (ProductName === "contact-us-form-4") {
    container = <ReUsableContactUsForm4 data={contactValues4} />;
  } else if (ProductName === "contact-us-form-5") {
    container = <ReUsableContactUsForm5 data={contactValues5} />;
  } else if (ProductName === "contact-us-form-7") {
    container = <ReUsableContactUsForm7 data={contactValues7} />;
  } else if (ProductName === "contact-us-form-8") {
    container = <ReUsableContactUsForm8 data={contactValues8} />;
  } else if (ProductName === "contact-us-form-9") {
    container = <ReUsableContactUsForm9 data={contactValues9} />;
  }

  if (ProductName === "career-form-1") {
    container = <ReUsableCareerForm1 data={careerValues1} />;
  } else if (ProductName === "career-form-2") {
    container = <ReUsableCareerForm2 data={careerValues2} />;
  } else if (ProductName === "career-form-3") {
    container = <ReUsableCareerForm3 data={careerValues3} />;
  }

  if (ProductName === "progress-bar-1") {
    container = <ReUsableProgressBar1 data={progressValues1} />;
  } else if (ProductName === "progress-bar-2") {
    container = <ReUsableProgressBar2 data={progressValues2} />;
  } else if (ProductName === "progress-bar-3") {
    container = <ReUsableProgressBar3 data={progressValues3} />;
  } else if (ProductName === "progress-bar-4") {
    container = <ReUsableProgressBar4 data={progressValues4} />;
  } else if (ProductName === "progress-bar-5") {
    container = <ReUsableProgressBar5 data={progressValues5} />;
  } else if (ProductName === "progress-bar-7") {
    container = <ReUsableProgressBar7 data={progressValues7} />;
  }

  if (ProductName === "side-bar-1") {
    container = <ReUsableSidebar1 data={sidebarValues1} />;
  } else if (ProductName === "side-bar-2") {
    container = <ReUsableSidebar2 data={sidebarValues2} />;
  } else if (ProductName === "side-bar-3") {
    container = <ReUsableSidebar3 data={sidebarValues3} />;
  } else if (ProductName === "side-bar-4") {
    container = <ReUsableSidebar4 data={sidebarValues4} />;
  } else if (ProductName === "side-bar-5") {
    container = <ReUsableSidebar5 data={sidebarValues5} />;
  } else if (ProductName === "side-bar-6") {
    container = <ReUsableSidebar6 data={sidebarValues6} />;
  } else if (ProductName === "side-bar-7") {
    container = <ReUsableSidebar7 data={sidebarValues7} />;
  }

  if (ProductName === "checkout") {
    container = <ReUsableCheckoutForm1 data={checkoutValues1} />;
  }

  if (ProductName === "breadcrumb-1") {
    container = <ReUsableBreadCrumb1 data={breadcrumbValue1} />;
  } else if (ProductName === "breadcrumb-2") {
    container = <ReUsableBreadCrumb2 data={breadcrumbValue2} />;
  } else if (ProductName === "breadcrumb-3") {
    container = <ReUsableBreadCrumb3 data={breadcrumbValue3} />;
  } else if (ProductName === "breadcrumb-4") {
    container = <ReUsableBreadCrumb4 data={breadcrumbValue4} />;
  } else if (ProductName === "breadcrumb-5") {
    container = <ReUsableBreadCrumb5 data={breadcrumbValue5} />;
  } else if (ProductName === "breadcrumb-6") {
    container = <ReUsableBreadCrumb6 data={breadcrumbValue6} />;
  } else if (ProductName === "breadcrumb-7") {
    container = <ReUsableBreadCrumb7 data={breadcrumbValue7} />;
  } else if (ProductName === "breadcrumb-8") {
    container = <ReUsableBreadCrumb8 data={breadcrumbValue8} />;
  } else if (ProductName === "breadcrumb-9") {
    container = <ReUsableBreadCrumb9 data={breadcrumbValue9} />;
  } else if (ProductName === "breadcrumb-10") {
    container = <ReUsableBreadCrumb10 data={breadcrumbValue10} />;
  } else if (ProductName === "breadcrumb-11") {
    container = <ReUsableBreadCrumb11 data={breadcrumbValue11} />;
  } else if (ProductName === "breadcrumb-12") {
    container = <ReUsableBreadCrumb12 data={breadcrumbValue12} />;
  }

  if (ProductName === "badge-1") {
    console.log("inside badge-7");
    container = <ReUsableBadge1 data={badgeValues} />;
  } else if (ProductName === "badge-2") {
    container = <ReUsableBadge2 data={badgeValues} />;
  } else if (ProductName === "badge-3") {
    container = <ReUsableBadge3 data={badgeValues} />;
  } else if (ProductName === "badge-4") {
    container = <ReUsableBadge4 data={badgeValues} />;
  } else if (ProductName === "badge-5") {
    container = <ReUsableBadge5 data={badgeValues} />;
  } else if (ProductName === "badge-6") {
    container = <ReUsableBadge6 data={badgeValues} />;
  } else if (ProductName === "badge-7") {
    container = <ReUsableBadge7 data={badgeValues7} />;
  } else if (ProductName === "badge-8") {
    container = <ReUsableBadge8 data={badgeValues8} />;
  }

  if (ProductName === "slider-1") {
    container = <ReUsableSlider1 data={sliderValue1} />;
  } else if (ProductName === "slider-2") {
    container = <ReUsableSlider2 data={sliderValue2} />;
  } else if (ProductName === "slider-3") {
    container = <ReUsableSlider3 data={sliderValue3} />;
  } else if (ProductName === "slider-4") {
    container = <ReUsableSlider4 data={sliderValue4} />;
  } else if (ProductName === "slider-5") {
    container = <ReUsableSlider5 data={sliderValue5} />;
  } else if (ProductName === "slider-6") {
    container = <ReUsableSlider6 data={sliderValue6} />;
  } else if (ProductName === "slider-7") {
    container = <ReUsableSlider7 data={sliderValue7} />;
  } else if (ProductName === "slider-8") {
    container = <ReUsableSlider8 data={sliderValue8} />;
  } else if (ProductName === "slider-9") {
    container = <ReUsableSlider9 data={sliderValue9} />;
  } else if (ProductName === "slider-10") {
    container = <ReUsableSlider10 data={sliderValue10} />;
  }

  return (
    <>
      <div className="main-reusables-container">
        <body>
          <div className="inner-reusables-container">
            <h1
              style={{
                fontFamily: "cursive",
                textAlign: "center",
                fontSize: "28px",
              }}
            >
              {localStorage.getItem("Product_Heading_Name")}
            </h1>
            {container}
            <br />
            <br />
          </div>
        </body>
      </div>
    </>
  );
}
