// reusable data
export const REUSABLE_INIT = "REUSABLE_INIT";
export const REUSABLE_SUCCESS = "REUSABLE_SUCCESS";
export const REUSABLE_FAILED = "REUSABLE_FAILED";

export const REUSABLE_HOME_INIT = "REUSABLE_HOME_INIT";
export const REUSABLE_HOME_SUCCESS = "REUSABLE_HOME_SUCCESS";
export const REUSABLE_HOME_FAILED = "REUSABLE_HOME_FAILED";

// reusable's data
export const REUSABLES_DATA_INIT = "REUSABLES_DATA_INIT";
export const REUSABLES_DATA_SUCCESS = "REUSABLES_DATA_SUCCESS";
export const REUSABLES_DATA_FAILED = "REUSABLES_DATA_FAILED";

//login data
export const LOGIN_INIT = "LOGIN_INIT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SNACKBAR_OPEN = "LOGIN_SNACKBAR_OPEN";
export const LOGIN_SNACKBAR_CLOSE = "LOGIN_SNACKBAR_CLOSE";
export const SIGNOUT = "SIGNOUT";

//signup data
export const SIGNUP_INIT = "SIGNUP_INIT";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const SIGNUP_SNACKBAR_OPEN = "SIGNUP_SNACKBAR_OPEN";
export const SIGNUP_SNACKBAR_CLOSE = "SIGNUP_SNACKBAR_CLOSE";

//cart

export const CART_INIT = "CART_INIT";
export const CART_SUCCESS = "CART_SUCCESS";
export const CART_FAILED = "CART_FAILED";
export const CART_TOTAL = "CART_TOTAL";

export const ADD_TO_CART = "ADD_TO_CART";
export const CART_SNACKBAR_OPEN = "CART_SNACKBAR_OPEN";
export const CART_SNACKBAR_CLOSE = "CART_SNACKBAR_CLOSE";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const REMOVE_SNACKBAR_OPEN = "REMOVE_SNACKBAR_OPEN";
export const REMOVE_SNACKBAR_CLOSE = "REMOVE_SNACKBAR_CLOSE";
export const CLEAR_FROM_CART = "CLEAR_FROM_CART";
