import Slider from "../assets/slider/images/slide-img.jpg";
import Slider2 from "../assets/slider/images/slider-img-2.jpg";
import Slider3 from "../assets/slider/images/slider-img-3.jpg";
import Slider4 from "../assets/slider/images/slide-img-4.jpg";
import Slider5 from "../assets/slider/images/slider-img-5.jpg";
import Slider6 from "../assets/slider/images/slider-img-6.jpg";
import Slider7 from "../assets/slider/images/slide-img-7.jpg";
import Slide1 from "../assets/slider/images/slide1.jpg";
import Slide2 from "../assets/slider/images/slide2.jpg";
import Slide3 from "../assets/slider/images/slide3.jpg";
import Mobile1 from "../assets/slider/images/mobile1.jpg";
import Mobile2 from "../assets/slider/images/mobile2.jpg";
import Mobile3 from "../assets/slider/images/mobile3.jpg";
import Mobile4 from "../assets/slider/images/mobile4.jpg";
import Mobile5 from "../assets/slider/images/mobile5.jpg";

export const sliderValue1 = {
  img1: `${Slider}`,
  img1_alt: "img1",
  img2: `${Slider2}`,
  img2_alt: "img2",
  img3: `${Slider3}`,
  img3_alt: "img3",
  img4: `${Slider4}`,
  img4_alt: "img4",
};

export const sliderValue2 = [
  {
    img: `${Slider}`,
    img_alt: "img1",
  },
  {
    img: `${Slider2}`,
    img_alt: "img2",
  },
  {
    img: `${Slider3}`,
    img_alt: "img3",
  },
  {
    img: `${Slider4}`,
    img_alt: "img4",
  },
  {
    img: `${Slider5}`,
    img_alt: "img5",
  },
  {
    img: `${Slider6}`,
    img_alt: "img6",
  },
];

export const sliderValue3 = [
  {
    img: `${Slider}`,
    card: "Card 1",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "Read More",
    backgroundColor: "#17141D",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#F0790E",
    headingSize: "24px",
    textColor: "#F8F8F8",
    textSize: "16px",
    fontWeight: "bold",
    filter: "none",
  },
  {
    img: `${Slider2}`,
    card: "Card 2",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "Read More",
    backgroundColor: "#17141D",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#F0790E",
    headingSize: "24px",
    textColor: "#F8F8F8",
    textSize: "16px",
    fontWeight: "bold",
    filter: "none",
  },
  {
    img: `${Slider3}`,
    card: "Card 3",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "Read More",
    backgroundColor: "#17141D",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#F0790E",
    headingSize: "24px",
    textColor: "#F8F8F8",
    textSize: "16px",
    fontWeight: "bold",
    filter: "none",
  },
  {
    img: `${Slider4}`,
    card: "Card 4",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "Read More",
    backgroundColor: "#17141D",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#F0790E",
    headingSize: "24px",
    textColor: "#F8F8F8",
    textSize: "16px",
    fontWeight: "bold",
    filter: "none",
  },
  {
    img: `${Slider5}`,
    card: "Card 5",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "Read More",
    backgroundColor: "#17141D",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#F0790E",
    headingSize: "24px",
    textColor: "#F8F8F8",
    textSize: "16px",
    fontWeight: "bold",
    filter: "none",
  },
  {
    img: `${Slider6}`,
    card: "Card 6",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "Read More",
    backgroundColor: "#17141D",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#F0790E",
    headingSize: "24px",
    textColor: "#F8F8F8",
    textSize: "16px",
    fontWeight: "bold",
    filter: "none",
  },
  {
    img: `${Slider7}`,
    card: "Card 7",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    btn: "Read More",
    backgroundColor: "#17141D",
    fontFamily: "Open Sans, sans-serif",
    headingColor: "#F0790E",
    headingSize: "24px",
    textColor: "#F8F8F8",
    textSize: "16px",
    fontWeight: "bold",
    filter: "none",
  },
];

export const sliderValue4 = {
  label: "Get Started",

  img1: "http://www.icons101.com/icon_png/size_256/id_79394/youtube.png",
  backgroundColor1: "#e53935",
  title1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
  subtitle1: "The Lorem Ipsum is normal distribution of letters.",

  img2: "http://www.icons101.com/icon_png/size_256/id_80975/GoogleInbox.png",
  backgroundColor2: "#1e88e5",
  title2: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  subtitle2: "The Lorem Ipsum is normal distribution of letters.",

  img3:
    "http://www.icons101.com/icon_png/size_256/id_76704/Google_Settings.png",
  backgroundColor3: "#43a047",
  title3: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  subtitle3:
    "The Lorem Ipsum is normal distribution of letters.",
};

export const sliderValue5 = [
  {
    title: "Vulputate Mollis Ultricies Fermentum Parturient",
    description:
      "Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis.",
    button: "Read More",
    image: "https://i.imgur.com/ZXBtVw7.jpg",
    titleColor: "#FFFFFF",
    descriptionColor: "#FFFFFF",
    buttonColor: "#000000",
    buttonBackground: "#FFD800",
  },
  {
    title: "Tortor Dapibus Commodo Aenean Quam",
    description:
      "Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec sed odio dui.",
    button: "Discover",
    image: "https://i.imgur.com/DCdBXcq.jpg",
    titleColor: "#FFFFFF",
    descriptionColor: "#FFFFFF",
    buttonColor: "#000000",
    buttonBackground: "#FFD800",
  },
  {
    title: "Phasellus volutpat metus",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis mollis, est non commodo luctus, nisi erat porttitor ligula.",
    button: "Buy now",
    image: "https://i.imgur.com/DvmN8Hx.jpg",
    titleColor: "#FFFFFF",
    descriptionColor: "#FFFFFF",
    buttonColor: "#000000",
    buttonBackground: "#FFD800",
  },
];

export const sliderValue6 = [
  {
    img: "https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg",
    name: "Shirley Fultz",
    profession: "Designer",
    content:
      "Its freeing to be able to catch up on customized news and not be distracted by a social media element on the same site",
    rating: 2.6,
  },

  {
    img: "https://mdbootstrap.com/img/Photos/Avatars/img%20(9).jpg",
    name: "Daniel Keystone",
    profession: "Architect",
    content:
      "The simple and intuitive design makes it easy for me use. I highly recommend Fetch to my peers.",
    rating: 3.403,
  },

  {
    img: "https://mdbootstrap.com/img/Photos/Avatars/img%20(10).jpg",
    name: "Theo Sorel",
    profession: "Designer",
    content:
      "I enjoy catching up with Fetch on my laptop, or on my phone when I am on the go!",
    rating: 4,
  },
];

export const sliderValue7 = [
  {
    type: "NEW",
  },
  {
    type: "CHEF SPECIAL",
  },

  {
    type: "BURGERS",
  },
  {
    type: "SANDWICHES",
  },
  {
    type: "FRIED CHICKEN",
  },
  {
    type: "STEAKS",
  },
  {
    type: "PASTA",
  },
  {
    type: "CHINESE",
  },
  {
    type: "NOODLES & RICE",
  },
  {
    type: "BBQ",
  },
  {
    type: "CHICKEN KARAHI",
  },
  {
    type: "MUTTON KARAHI",
  },
  {
    type: "HANDI",
  },
  {
    type: "ROLLS",
  },
  {
    type: "MOCKTAILS & SHAKES",
  },
  {
    type: "DESSERTS",
  },
  {
    type: "DEALS",
  },
  {
    type: "PLATTERS",
  },
  {
    type: "ROTI PARATHA",
  },
  {
    type: "COLD BEVERAGES",
  },
  {
    type: "ADD ONS",
  },
];

export const sliderValue8 = [
  {
    image: `${Slide1}`,
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    name: "Daniel Radcliffe",
    fontFamily: "Open Sans, sans-serif",
    textColor: "#fff",
    textSize: "24px",
    height: "100%",
    width: "100%",
    fontWeight: "bold",
  },
  {
    image: `${Slide2}`,
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    name: "Kit Harington",
    fontFamily: "Open Sans, sans-serif",
    textColor: "#fff",
    textSize: "24px",
    height: "100%",
    width: "100%",
    fontWeight: "bold",
  },
  {
    image: `${Slide3}`,
    comment:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi molestias delectus quos!",
    name: "Shahid Afridi",
    fontFamily: "Open Sans, sans-serif",
    textColor: "#fff",
    textSize: "24px",
    height: "100%",
    width: "100%",
    fontWeight: "bold",
  },
];

export const sliderValue9 = [
  {
    imageNo: 0,
    image: `${Mobile1}`,
    thumbnail: `${Mobile1}`,
    class: "active",
    height: "100%",
    width: "100%",
  },
  {
    imageNo: 1,
    image: `${Mobile2}`,
    thumbnail: `${Mobile2}`,
    class: "",
    height: "100%",
    width: "100%",
  },
  {
    imageNo: 2,
    image: `${Mobile3}`,
    thumbnail: `${Mobile3}`,
    class: "",
    height: "100%",
    width: "100%",
  },
  {
    imageNo: 3,
    image: `${Mobile4}`,
    thumbnail: `${Mobile4}`,
    class: "",
    height: "100%",
    width: "100%",
  },
  {
    imageNo: 4,
    image: `${Mobile5}`,
    thumbnail: `${Mobile5}`,
    class: "",
    height: "100%",
    width: "100%",
  },
];

export const sliderValue10 = [
  {
    imageNo: 0,
    image: `${Slider}`,
    comment:
      "This is my comment 1. This is my comment 1. This is my comment 1. This is my comment 1.",
    fontFamily: "Open Sans, sans-serif",
    textColor: "#fff",
    textSize: "24px",
    height: "100%",
    width: "100%",
    fontWeight: "bold",
  },
  {
    imageNo: 1,
    image: `${Slider2}`,
    comment:
      "This is my comment 2. This is my comment 2. This is my comment 2. This is my comment 2.",
    fontFamily: "Open Sans, sans-serif",
    textColor: "#fff",
    textSize: "24px",
    height: "100%",
    width: "100%",
    fontWeight: "bold",
  },
  {
    imageNo: 2,
    image: `${Slider3}`,
    comment:
      "This is my comment 3. This is my comment 3. This is my comment 3. This is my comment 3.",
    fontFamily: "Open Sans, sans-serif",
    textColor: "#222",
    textSize: "24px",
    height: "100%",
    width: "100%",
    fontWeight: "bold",
  },
  {
    imageNo: 3,
    image: `${Slider4}`,
    comment:
      "This is my comment 4. This is my comment 4. This is my comment 4. This is my comment 4.",
    fontFamily: "Open Sans, sans-serif",
    textColor: "#222",
    textSize: "24px",
    height: "100%",
    width: "100%",
    fontWeight: "bold",
  },
  {
    imageNo: 4,
    image: `${Slider5}`,
    comment:
      "This is my comment 5. This is my comment 5. This is my comment 5. This is my comment 5.",
    fontFamily: "Open Sans, sans-serif",
    textColor: "#222",
    textSize: "24px",
    height: "100%",
    width: "100%",
    fontWeight: "bold",
  },
];
