import logoImg from "../assets/logo.png";

import footer4LatestEvents1 from "../assets/footer/images/footer-4-60x60.jpg";
import footer4LatestEvents2 from "../assets/footer/images/footer-4-40x40.jpg";

import footer8LogoImg from "../assets/footer/images/footer_8_logo.svg";
import footer8SocialIconFb from "../assets/footer/images/footer-8-social-icon/facebook-01.png";
import footer8SocialIconInsta from "../assets/footer/images/footer-8-social-icon/Insta-Logo-01.png";
import footer8SocialIconPinterest from "../assets/footer/images/footer-8-social-icon/Pinterest-Icon.png";

export const footerValues1 = {
  footerBackgroundColor: "#000000",
  footerLinksFontColor: "#FFA500",
  footerLinksFontSize: "14px",
  footerLinksFontFamily: "'Open Sans', 'Open Sans', sans-serif",
  footerLinksFontWeight: "bold",
  border: "solid thin #dc3545",
  footerImg: {
    src: logoImg,
    width: "150px",
    alt: "Footer-Image",
  },
  footerDescription: {
    fontColor: "#FFFFFF",
    fontSize: "14px",
    fontFamily: "'Open Sans', 'Open Sans', sans-serif",
    fontWeight: "bold",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elittellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
  },
  footerLinks: {
    Company: [
      {
        name: "Home",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "About",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Service",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Contacts",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "400",
      },
    ],
    Business: [
      {
        name: "Project",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Our Team",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Facts",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Customers",
        href: "#",
        fontColor: "#FFFFFF",
        fontSize: "16px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "400",
      },
    ],
  },
  contact: {
    title: "Get In Touch",
    details: [
      {
        address: "30, Clan Street Cresent tower, Washington, DC",
        icon: "map-marker",
        href: "#",
        fontColor: "#fff",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "bold",
      },
      {
        email: "info@example.com",
        icon: "envelope",
        href: "#",
        fontColor: "#fff",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "bold",
      },
      {
        phoneNo: "1-800-1234-567",
        icon: "phone",
        href: "#",
        fontColor: "#fff",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "bold",
      },
      {
        mobileNo: "+001 123-456-78910",
        icon: "mobile",
        href: "#",
        fontColor: "#fff",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "bold",
      },
    ],
  },
  footerSocials: {
    icons: [
      {
        name: "facebook",
        href: "#",
        fontColor: "#FFA500",
      },
      {
        name: "twitter",
        href: "#",
        fontColor: "#FFA500",
      },
      {
        name: "linkedin",
        href: "#",
        fontColor: "#FFA500",
      },
      {
        name: "pinterest",
        href: "#",
        fontColor: "#FFA500",
      },
    ],
  },
  copyRights: {
    content: "Powered by InertiaSoft",
    fontColor: "#FFA500",
    fontSize: "14px",
    fontFamily: "'Open Sans', 'Open Sans', sans-serif",
    fontWeight: "bold",
  },
};

export const footerValues2 = {
  footer: {
    title: "Get in Touch",
    backgroundColor: "#fff",
    fontColor: "#444444",
    fontSize: "16px",
    fontFamily: "'Open Sans', 'Open Sans', sans-serif",
    fontWeight: "bold",
  },
  subscribeForm: {
    successMsg: {
      fontColor: "#263b5e",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    errMsg: {
      fontColor: "#263b5e",
      fontSize: "10px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    emailField: {
      placeHolder: "Email Address",
      fontSize: "16px",
    },
    content: {
      details: "Don’t miss any updates of our new templates and extensions.!",
      fontColor: "rgb(142, 142, 142)",
      fontSize: "10px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    button: {
      backgroundColor: "#5e2ced",
      fontColor: "#FFFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
      content: "Subscribe",
    },
  },
  footerLinks: {
    Download: [
      {
        name: "Company",
        href: "#",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Android App",
        href: "#",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "iOS App",
        href: "#",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Desktop",
        href: "#",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Projects",
        href: "#",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "My Tasks",
        href: "#",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
    ],
    Help: [
      {
        name: "FAQ",
        href: "#",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Terms",
        href: "#",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Reporting",
        href: "#",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Documentation",
        href: "#",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Suppory",
        href: "#",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Privacy",
        href: "#",
        fontColor: "#8E8E8E",
        fontSize: "14px",
        fontFamily: "'Open Sans', 'Open Sans', sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  socials: {
    title: "Team Solutions",
    icons: [
      {
        name: "facebook",
        href: "#",
        backgroundColor: "#5e2ced",
      },
      {
        name: "twitter",
        href: "#",
        backgroundColor: "#5e2ced",
      },
      {
        name: "linkedin",
        href: "#",
        backgroundColor: "#5e2ced",
      },
      {
        name: "pinterest",
        href: "#",
        backgroundColor: "#5e2ced",
      },
    ],
  },
};

export const footerValues3 = {
  title: "Get in Touch",
  backgroundColor: "",
  fontColor: "#222",
  fontSize: "10px",
  fontFamily: "'Open Sans', 'Open Sans', sans-serif",
  fontWeight: "normal",
  logo: {
    src: `${logoImg}`,
    imgAlt: "InertiaSoft",
  },
  content: {
    description:
      "We are looking for bright individuals Lorem Ipsum passages, Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    href: "http://google.com",
    label: "",
    fontColor: "#FFF",
    fontSize: "14px",
    fontFamily: "'Open Sans', 'Open Sans', sans-serif",
    fontWeight: "normal",
  },
  navigation: {
    headingOne: {
      content: "Contact",
      addressDetails: "Cresent tower, Washington, DC",
      contactDetails: "Phone: 9924 244 45 66 356",
      email: {
        label: "Email:",
        address: " contact@example.com",
        href: "http://inertiasoft.net",
      },
      fontColor: "#FFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    headingTwo: {
      content: "Links",
      addressDetails: "Via Rossini 10, 10136 Turin Italy",
      contactDetails: "Phone: (0039) 333 12 68 347",
      email: {
        label: "Email:",
        address: "contact@example.com",
      },
      fontColor: "#FFF",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
  },
  copyRight: {
    href: "http://inertisoft.net",
    companyName: "Powered by InertiaSoft",
    fontColor: "#fff",
    fontSize: "14px",
    fontFamily: "'Open Sans', 'Open Sans', sans-serif",
    fontWeight: "normal",
  },
  linkOneItems: [
    {
      href: "http://ineritsoft.net",
      content: "Section-1",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    {
      href: "http://google.com",
      content: "Section-2",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    {
      href: "http://ineritsoft.net",
      content: "Section-3",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    {
      href: "http://ineritsoft.net",
      content: "Section-4",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
  ],
  linkTwoItems: [
    {
      href: "http://ineritsoft.net",
      content: "Section-5",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    {
      href: "http://ineritsoft.net",
      content: "Section-6",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    {
      href: "http://ineritsoft.net",
      content: "Section-7",
      fontColor: "#fff",
      fontSize: "14px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
  ],
  socialMediaLinks: [
    {
      iconClass: "fa fa-facebook",
      href: "http://facebook.com",
      label: "",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    {
      iconClass: "fa fa-twitter",
      href: "http://twitter.com",
      label: "",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    {
      iconClass: "fa fa-google-plus",
      href: "http://google.com",
      label: "",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    {
      iconClass: "fa fa-pinterest",
      href: "http://google.com",
      label: "",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily: "'Open Sans', 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
  ],
};

export const footerValues4 = [
  {
    backgroundColor: "#0139af",
    heading: {
      content: "Stay in touch",
      fontColor: "#FFF",
      fontSize: "24px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    salesEmail: {
      content: "sales@example.com",
      fontColor: "#d2d1d1",
      fontSize: "16px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    supportEmail: {
      content: "support@example.com",
      fontColor: "#d2d1d1",
      fontSize: "16px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    phone: {
      content: "(123) 456-789",
      fontColor: "#d2d1d1",
      fontSize: "16px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    footerSocial: {
      icons: [
        {
          name: "facebook",
          href: "#",
          fontColor: "#fff",
          fontSize: "20px",
          fontFamily: "'Open Sans', 'Open Sans', sans-serif",
          fontWeight: "normal",
        },
        {
          name: "twitter",
          href: "#",
        },
        {
          name: "linkedin",
          href: "#",
        },
      ],
    },
  },
  {
    heading: {
      content: "Latest Events",
      fontColor: "#fff",
      fontSize: "24px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    events: [
      {
        img: `${footer4LatestEvents2}`,
        content: "vulputate velit esse consequat.",
        date: "January 30, 2021",
        href: "#",
        fontColor: "#222",
        fontSize: "10px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
      },
      {
        img: `${footer4LatestEvents2}`,
        content: "vulputate velit esse consequat.",
        date: "January 30, 2021",
        href: "#",
        fontColor: "#222",
        fontSize: "10px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
      },
    ],
  },
  {
    heading: {
      content: "Opening Hours",
      fontColor: "#fff",
      fontSize: "24px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    openingTimes: [
      {
        icon: "check",
        day: "Monday",
        time: " 8am - 5pm",
        fontColor: "#d2d1d1",
        fontSize: "14px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
      },
      {
        icon: "check",
        day: "Tue-Fri",
        time: " 8am - 12am",
        fontColor: "#d2d1d1",
        fontSize: "14px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
      },
      {
        icon: "check",
        day: "Sat-Sun",
        time: " 7am - 1am",
        fontColor: "#d2d1d1",
        fontSize: "14px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
      },
      {
        icon: "check",
        day: "Holidays ",
        time: " 12pm - 12am",
        fontColor: "#d2d1d1",
        fontSize: "14px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
      },
    ],
  },
  {
    heading: {
      content: "Latest Events Images",
      fontColor: "#fff",
      fontSize: "24px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    events: [
      {
        image: `${footer4LatestEvents1}`,
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "27px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        image: `${footer4LatestEvents1}`,
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "27px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        image: `${footer4LatestEvents1}`,
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "20px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        image: `${footer4LatestEvents1}`,
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "20px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        image: `${footer4LatestEvents1}`,
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "20px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        image: `${footer4LatestEvents1}`,
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "20px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
    ],
  },
];

export const footerValues5 = [
  {
    logoImg,
    href: "#",
    fontColor: "#fff",
    fontSize: "50px",
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "normal",
    backgroundColor: "#3c3d41",
  },
  {
    heading: {
      content: "Get started",
      fontColor: "#fff",
      fontSize: "18px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    links: [
      {
        name: "Home",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Signup",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Downloads",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    heading: {
      content: "About Us",
      fontColor: "#fff",
      fontSize: "18px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    links: [
      {
        name: "Company Information",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Contact us",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Reviews",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    heading: {
      content: "Support",
      fontColor: "#fff",
      fontSize: "18px",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "bold",
    },
    links: [
      {
        name: "FAQ",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Help Desk",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Forums",
        href: "#",
        fontColor: "#d2d1d1",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    socialIcons: [
      {
        name: "twitter",
        href: "#",
        fontColor: "#f9f9f9",
        fontSize: "32px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "facebook",
        href: "#",
        fontColor: "#f9f9f9",
        fontSize: "32px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "google-plus",
        href: "#",
        fontColor: "#f9f9f9",
        fontSize: "32px",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    contactUsButton: {
      content: "Contact US",
      fontColor: "#fff",
      fontSize: "",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "normal",
      backgroundColor: " #d84b6b",
      borderRadius: "20px",
      border: "none",
      width: "150px",
    },
    copyRightSection: {
      content: "Powered by InertiaSoft",
      backgroundColor: "#333333",
      fontColor: "#ccc",
      fontSize: "",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "normal",
      width: "100%",
    },
  },
];

export const footerValues7 = [
  {
    backgroundColor: "#2196f3",
    footerHeading: {
      content: "Stay in touch",
      fontColor: "#fff",
      fontSize: "",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "",
    },
    footerContent: {
      content: "Don’t miss any updates of our new templates and extensions!",
      fontColor: "#fff",
      fontSize: "",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "",
    },
  },
  {
    heading: {
      content: "Get Started",
      fontColor: "#fff",
      fontSize: "",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "",
    },
    links: [
      {
        name: "Home",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "About",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Sign Up",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Downloads",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    heading: {
      content: "Support",
      fontColor: "#fff",
      fontSize: "",
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: "",
    },
    links: [
      {
        name: "FAQ",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Help Desk",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Forums",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
      {
        name: "Reviews",
        href: "#",
        fontColor: "#fff",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "normal",
      },
    ],
  },
  {
    copyRightSection: {
      backgroundColor: "#1a78c2",
      content: {
        description: "© Powered by",
        fontColor: "#f7f7f7",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "",
      },
      anchor: {
        content: " InertiaSoft",
        href: "http://inertiasoft.net",
        fontColor: "#f7f7f7",
        fontSize: "",
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "",
      },
    },
  },
];

export const footerValues8 = {
  backgroundColor: "#262626",
  width: "100%",
  logo: {
    href: "http://google.com",
    src: footer8LogoImg,
    altText: "Logo-Image",
    fontColor: "#fff",
    fontSize: "31px",
    fontFamily:
      "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
    fontWeight: "normal",
  },
  socialIcons: [
    {
      image: footer8SocialIconFb,
      href: "https://www.facebook.com/foodsinn/",
      altText: "Icon-Image",
      fontColor: "#fff",
      fontSize: "31px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "normal",
    },
    {
      image: footer8SocialIconInsta,
      href: "https://www.instagram.com/foods_inn/",
      altText: "Icon-Image",
      fontColor: "#fff",
      fontSize: "31px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "normal",
    },
    {
      image: footer8SocialIconPinterest,
      href: "https://www.instagram.com/foods_inn/",
      altText: "Icon-Image",
      fontColor: "#fff",
      fontSize: "31px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "normal",
    },
  ],
  copyRightSection: {
    content: {
      date: "© ",
      content: " Powered by ",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "normal",
    },
    anchor: {
      content: " InertiaSoft",
      href: "http://inertiasoft.net",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "normal",
    },
  },
};

export const footerValues9 = {
  backgroundColor: "#3f51b5",
  separatorLine: {
    backgroundColor: "#fff",
    margin: "0 15%",
  },
  links: [
    {
      name: "About Us",
      href: "#",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "bold",
    },
    {
      name: "Products",
      href: "#",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "bold",
    },
    {
      name: "Awards",
      href: "#",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "bold",
    },
    {
      name: "Help",
      href: "#",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "bold",
    },
    {
      name: "Contact",
      href: "#",
      fontColor: "#fff",
      fontSize: "20px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "bold",
    },
  ],
  socialIcons: [
    {
      name: "facebook",
      href: "#",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "normal",
    },
    {
      name: "twitter",
      href: "#",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "normal",
    },
    {
      name: "google-plus",
      href: "#",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "normal",
    },
    {
      name: "linkedin",
      href: "#",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "normal",
    },
    {
      name: "instagram",
      href: "#",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "normal",
    },
    {
      name: "pinterest",
      href: "#",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "normal",
    },
  ],
  content: {
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam remaperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur.",
    fontColor: "#fff",
    fontSize: "15px",
    fontFamily:
      "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
    fontWeight: "normal",
    lineHeight: "1.7rem",
  },
  copyRightSection: {
    backgroundColor: "#324191",
    content: {
      date: "Powered by, ",
      content: " Design and Developed By ",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "normal",
    },
    anchor: {
      content: " InertiaSoft",
      href: "http://inertiasoft.net",
      fontColor: "#fff",
      fontSize: "11px",
      fontFamily:
        "'Open Sans', 'Open Sans', sans-serif, 'Open Sans', sans-serif;",
      fontWeight: "normal",
    },
  },
};

export const footerValues10 = {
  content: "Powered By InertiaSoft",
  copyRightYearFrom: "2020",
  copyRightYearTo: "2021",
  backgroundColor: "#000",
  website: "inertiasoft",
  fontColor: "#fff",
  fontSize: "13px",
  fontFamily: "'Open Sans', 'Open Sans', sans-serif",
  fontWeight: "normal",
  borderTop: "1px solid red",
};

export const footerValues11 = {
  backgroundColor: "rgb(44, 26, 106)",
  footerAbout: {
    content: {
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      fontColor: "rgb(153, 153, 204)",
      fontSize: "28.125px",
      fontFamily: "karla, 'Open Sans', sans-serif",
      fontWeight: "400",
    },
    emailAddress: {
      description: "info@example.com",
      fontColor: "#fff",
      fontSize: "28.125px",
      fontFamily: "karla, 'Open Sans', sans-serif",
      fontWeight: "400",
    },
  },
  footerAddress: {
    heading: {
      content: "OFFICE",
    },
    backgroundColor: "background-color: rgb(44, 26, 106)",
    content: "30, Clan Street Cresent tower, Washington, DC",
    footerMailto: "info@example.com",
    contact: '+1234 567 8910',
    fontColor: "#fff",
    fontSize: "16px",
    fontFamily: "karla, 'Open Sans', sans-serif",
    fontWeight: "400",
  },
  footerDivider: {
    borderTopColor: "rgba(255,255,255,.05)",
    border: "0",
    borderTop: "1px solid rgba(0,0,0,.1)",
  },
  footerLinkHeading: {
    fontColor: "#99c",
    fontSize: "16px",
    fontFamily: "karla, 'Open Sans', sans-serif",
    fontWeight: "700",
  },
  footerLinks: {
    Help: [
      {
        name: "FAQ",
        href: "#",
        fontColor: "#fff",
        fontSize: "16px",
        fontFamily: "karla, 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Terms",
        href: "#",
        fontColor: "#fff",
        fontSize: "16px",
        fontFamily: "karla, 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Help",
        href: "#",
        fontColor: "#fff",
        fontSize: "16px",
        fontFamily: "karla, 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Services",
        href: "#",
        fontColor: "#fff",
        fontSize: "16px",
        fontFamily: "karla, 'Open Sans', sans-serif",
        fontWeight: "400",
      },
    ],
    Quick: [
      {
        name: "FAQ",
        href: "#",
        fontColor: "#fff",
        fontSize: "16px",
        fontFamily: "karla, 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Terms",
        href: "#",
        fontColor: "#fff",
        fontSize: "16px",
        fontFamily: "karla, 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Help",
        href: "#",
        fontColor: "#fff",
        fontSize: "16px",
        fontFamily: "karla, 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Services",
        href: "#",
        fontColor: "#fff",
        fontSize: "16px",
        fontFamily: "karla, 'Open Sans', sans-serif",
        fontWeight: "400",
      },
    ],
    Links: [
      {
        name: "FAQ",
        href: "#",
        fontColor: "#fff",
        fontSize: "16px",
        fontFamily: "karla, 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Terms",
        href: "#",
        fontColor: "#fff",
        fontSize: "16px",
        fontFamily: "karla, 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Help",
        href: "#",
        fontColor: "#fff",
        fontSize: "16px",
        fontFamily: "karla, 'Open Sans', sans-serif",
        fontWeight: "400",
      },
      {
        name: "Services",
        href: "#",
        fontColor: "#fff",
        fontSize: "16px",
        fontFamily: "karla, 'Open Sans', sans-serif",
        fontWeight: "400",
      },
    ],
  },
  copyRightSection: {
    content: {
      footerYear: "© 2020",
      footerName: "Release",
      footerRights: " Version.",
      fontColor: "#fff",
      fontSize: "16px",
      fontFamily: "karla, 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
    designBy: {
      label: "Powered By",
      name: "InertiaSoft",
      fontColor: "#fff",
      fontSize: "16px",
      fontFamily: "karla, 'Open Sans', sans-serif",
      fontWeight: "normal",
    },
  },
};
