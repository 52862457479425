import React, { Component } from "react";
import PropTypes from "prop-types";
import ScrollMenu from "react-horizontal-scrolling-menu";
import "./scrollNav.css";
import { getReusableData } from "../../sources/reusablesource";
import { getReusablesData } from "../../sources/reusabledatasource";
import { connect } from "react-redux";

let list = [];

const MenuItem = ({ text, selected }) => {
  return <div className={`menu-item ${selected ? "active" : ""}`}>{text}</div>;
};

export const Menu = (list, selected) =>
  list.map((el) => {
    const { id, name } = el;
    return <MenuItem text={name} key={id} selected={selected} />;
  });

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};
Arrow.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
export const ArrowRight = Arrow({ text: ">", className: "arrow-next" });

class ScrollNav extends Component {
  state = {
    alignCenter: true,
    clickWhenDrag: false,
    dragging: true,
    hideArrows: true,
    hideSingleArrow: true,
    itemsCount: list.length,
    scrollToSelected: false,
    selected: localStorage.getItem("selected") || 7,
    translate: 0,
    transition: 0.3,
    wheel: true,
  };

  constructor(props) {
    super(props);
    this.menu = null;
  }

  onFirstItemVisible = () => {};

  onLastItemVisible = () => {};

  onUpdate = ({ translate }) => {
    this.setState({ translate });
  };

  componentDidMount() {
    this.setState({ translate: 75.609375 });
    this.props.getreusable();
    this.props.getreusablesdata(this.state.selected);
  }

  onSelect = (key) => {
    this.setState({ selected: key });
    this.props.getreusablesdata(key);
  };

  // componentDidUpdate(prevProps, prevState) {
  //   const { alignCenter } = prevState;
  //   const { alignCenter: alignCenterNew } = this.state;
  //   if (alignCenter !== alignCenterNew) {
  //     this.menu.setInitial();
  //   }
  // }

  setSelected = (ev) => {
    const { value } = ev.target;
    this.setState({ selected: value });
  };

  render() {
    localStorage.setItem("productData", this.props.reuseabledata);
    localStorage.setItem("selected", this.state.selected);
    if (this.props.data) {
      const data = this.props.data.data.filter((item) => {
        return item.type !== "T";
      });
      this.menuItems = Menu(this.props.data ? data : null, this.state.selected);
    }
    const {
      alignCenter,
      clickWhenDrag,
      hideArrows,
      dragging,
      hideSingleArrow,
      scrollToSelected,
      selected,
      wheel,
    } = this.state;

    const menu = this.menuItems;

    return (
      <div className="App">
        <ScrollMenu
          alignCenter={alignCenter}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          clickWhenDrag={clickWhenDrag}
          data={menu}
          dragging={dragging}
          hideArrows={hideArrows}
          hideSingleArrow={hideSingleArrow}
          onFirstItemVisible={this.onFirstItemVisible}
          onLastItemVisible={this.onLastItemVisible}
          onSelect={this.onSelect}
          onUpdate={this.onUpdate}
          ref={(el) => (this.menu = el)}
          scrollToSelected={scrollToSelected}
          selected={selected}
          wheel={wheel}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.reusable.data,
    reuseabledata: state.reuseabledata.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getreusable: () => dispatch(getReusableData()),
    getreusablesdata: (id) => dispatch(getReusablesData(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrollNav);
