import React, { Component } from "react";
import ScrollNav from "../../commons/ScrollNav/scrollnav";
import Nav from "../../commons/Navbar/navbar";
import { connect } from "react-redux";
import "./Reus2.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckuotItem from "../CheckoutItem/checkuotItem";
import { addToCart } from "../../store/actions/cartaction";
import Snackbar from "../../commons/Snackbar/snackbar";
class Reus2 extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, index: 0, loaded: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getProductName = (name, displayName) => {
    localStorage.setItem("ProductName", name);
    localStorage.setItem("Product_Heading_Name", displayName);
  };

  notify = (message) => {
    toast.success(message);
  };

  render() {
    return (
      <>
        <Snackbar
          openSnackbar={this.props.snackbarOpen}
          closeSnackbar={this.props.onSnackBarClick}
          snackbarmessage={this.props.snackbarMessage}
          clickSnackbar={this.props.onSnackBarClick}
        />
        <div>
          <ToastContainer
            position="bottom-left"
            autoClose={10000}
            newestOnTop={false}
            type="success"
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        <div className="main-navbar-style">
          <Nav navPadding={true} quantity={localStorage.getItem("total")} />
        </div>
        <div className="main-nav-cont">
          <ScrollNav />
        </div>
        <div className="main-cards-container">
          <CheckuotItem />
          <div className="row">
            {this.props.data
              ? this.props.data.data.map((items, index) => {
                  const { name, attachments, displayName } = items;
                  return (
                    <div
                      key={index}
                      className={
                        items.products.name === "Side-bars"
                          ? "col-lg-4 col-md-6 col-sm-12 re-card-padding"
                          : items.products.name === "Nav-bars"
                          ? "col-lg-12 col-md-12 col-sm-12 re-card-padding"
                          : items.products.name === "Footers"
                          ? "col-lg-12 col-md-12 col-sm-12 re-card-padding"
                          : items.products.name === "Sliders"
                          ? "col-lg-12 col-md-12 col-sm-12 re-card-padding"
                          : items.products.name === "Cards"
                          ? "col-lg-4 col-md-6 col-sm-12 re-card-padding"
                          : items.products.name === "Contact-Us Form"
                          ? "col-lg-12 col-md-12 col-sm-12 re-card-padding"
                          : items.products.name === "Career Form"
                          ? "col-lg-12 col-md-12 col-sm-12 re-card-padding"
                          : items.products.name === "Progress-bars"
                          ? "col-lg-4 col-md-6 col-sm-12 re-card-padding"
                          : items.products.name === "Badges"
                          ? "col-lg-4 col-md-6 col-sm-12 re-card-padding"
                          : items.products.name === "Breadcrumbs"
                          ? "col-lg-4 col-md-6 col-sm-12 re-card-padding"
                          : "col-lg-4 col-md-6 col-sm-12 re-card-padding"
                      }
                    >
                      <div className="re-card">
                        <h2>{displayName}</h2>

                        {items.products.name === "Side-bars" ? (
                          attachments[0] && this.state.width > 768 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[0].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : attachments[1] && this.state.width < 769 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[1].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : (
                            "loading..."
                          )
                        ) : (
                          ""
                        )}

                        {items.products.name === "Nav-bars" ? (
                          attachments[0] && this.state.width > 768 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[0].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : attachments[1] && this.state.width < 769 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[1].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : (
                            "loading..."
                          )
                        ) : (
                          ""
                        )}

                        {items.products.name === "Footers" ? (
                          attachments[0] && this.state.width > 768 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[0].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : attachments[1] && this.state.width < 769 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[1].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : (
                            "loading..."
                          )
                        ) : (
                          ""
                        )}

                        {items.products.name === "Sliders" ? (
                          attachments[0] && this.state.width > 768 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[0].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : attachments[1] && this.state.width < 769 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[1].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : (
                            "loading..."
                          )
                        ) : (
                          ""
                        )}

                        {items.products.name === "Cards" ? (
                          attachments[0] && this.state.width > 768 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[0].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : attachments[1] && this.state.width < 769 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[1].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : (
                            "loading..."
                          )
                        ) : (
                          ""
                        )}

                        {items.products.name === "Contact-Us Form" ? (
                          attachments[0] && this.state.width > 768 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[0].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : attachments[1] && this.state.width < 769 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[1].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : (
                            "loading..."
                          )
                        ) : (
                          ""
                        )}

                        {items.products.name === "Career Form" ? (
                          attachments[0] && this.state.width > 768 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[0].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : attachments[1] && this.state.width < 769 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[1].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : (
                            "loading..."
                          )
                        ) : (
                          ""
                        )}

                        {items.products.name === "Progress-bars" ? (
                          attachments[0] && this.state.width > 768 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[0].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : attachments[1] && this.state.width < 769 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[1].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : (
                            "loading..."
                          )
                        ) : (
                          ""
                        )}

                        {items.products.name === "Badges" ? (
                          attachments[0] && this.state.width > 768 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[0].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : attachments[1] && this.state.width < 769 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[1].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : (
                            "loading..."
                          )
                        ) : (
                          ""
                        )}

                        {items.products.name === "Breadcrumbs" ? (
                          attachments[0] && this.state.width > 768 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[0].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : attachments[1] && this.state.width < 769 ? (
                            <img
                              style={
                                this.state.loaded ? {} : { display: "none" }
                              }
                              src={attachments[1].url}
                              alt="sidebar1"
                              onLoad={() => this.setState({ loaded: true })}
                            />
                          ) : (
                            "loading..."
                          )
                        ) : (
                          ""
                        )}

                        <div className="add-to-btn-cont">
                          <button
                            className={"home-preview-btn"}
                            onClick={() => this.props.addCart(items)}
                          >
                            Add To Cart
                          </button>
                          <Link to="/preview" target="_blank" rel="noreferrer">
                            <button
                              onClick={this.getProductName.bind(
                                this,
                                name,
                                displayName
                              )}
                              className="home-preview-btn"
                            >
                              Preview
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })
              : "loading"}
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    data: state.reuseabledata.data,
    cartItems: state.cart.cartItems,
    snackbarOpen: state.cart.snackbarOpen,
    snackbarMessage: state.cart.snackbarMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addCart: (product) => dispatch(addToCart(product)),
    onSnackBarClick: () => dispatch({ type: "CART_SNACKBAR_CLOSE" }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reus2);
