export const progressValues1 = {
  btnContent: "Submit",
  btnColor: "301934 ",
  btnText: "#FFFFFF",
  iconColor: "#FFFFFF",
};

export const progressValues2 = {
  percent: 75,
  cssGradient: '"linear-gradient(to right, #fefb72, #f0bb31)"',
};

export const progressValues3 = {
  className: "secondary",
};

export const progressValues4 = {
  progressContainerBackground: "#FFFFFF",
  progressBarColor: "#7745FF",
};

export const progressValues5 = {
  bgColor: "bg-success",
  striped: "progress-bar-striped active",
  animated: "progress-bar-animated",
};

export const progressValues7 = {
  heading1: "Personal",
  heading2: "Address",
  heading3: "Payment",
  heading4: "Review",
  heading5: "Finish",
};
