import React, { Component } from "react";
import image1 from "../../assets/images/image-1.png";
import Signup from "../Signup/signup";
import { URL } from "../../Constants/url";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Nav from "../../commons/Navbar/navbar";
import Loader from "react-loader-spinner";

class ForgotPasswordEmail extends Component {
  state = {
    email: "",
    open: false,
    isLoading: false,
  };
  // useEffect(() => {
  //   getLoginData;
  //   const sign_in_btn = document.querySelector("#sign-in-btn");
  //   const sign_up_btn = document.querySelector("#sign-up-btn");
  //   const container = document.querySelector(".cont");

  //   sign_up_btn.addEventListener("click", () => {
  //     container.classList.add("sign-up-mode");
  //   });

  //   sign_in_btn.addEventListener("click", () => {
  //     container.classList.remove("sign-up-mode");
  //   });
  // }, []);

  componentDidMount() {
    const sign_in_btn = document.querySelector("#sign-in-btn");
    const container = document.querySelector(".cont");

    sign_in_btn.addEventListener("click", () => {
      container.classList.remove("sign-up-mode");
    });
  }

  handleOnSubmit = (event) => {
    this.setState({
      isLoading: true,
    });
    var that = this;
    event.preventDefault();

    if (this.state.email === "") {
      this.setState({
        isLoading: false,
      });
      this.notify("All fields are required", "fail");
    } else {
      fetch(`${URL}/forgotpassword`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.state.email,
        }),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (parsedJson) {
          that.setState({ isLoading: false });
          if (parsedJson.errors) {
            that.notify(parsedJson.errors.email, "fail");
          } else if (parsedJson.status === 404) {
            that.notify(parsedJson.message, "fail");
          } else {
            that.notify(parsedJson.message);
          }
        })
        .catch(function (error) {
          return error;
        });
    }
  };

  notify = (message, status) => {
    if (status === "fail") {
      toast.error(message);
    } else {
      toast.success(message);
    }
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <>
        <Nav />
        <div>
          <ToastContainer
            position="bottom-right"
            autoClose={10000}
            newestOnTop={false}
            type="success"
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        <div className="cont">
          <div className="forms-container">
            <div className="signin-signup">
              <form action="#" className="sign-in-form showcase-form">
                <h2 className="title">Forget Password</h2>
                <div className="showcase-input-field">
                  <i className="fas fa-user"></i>
                  <input
                    autoComplete="off"
                    type="text"
                    placeholder="Email *"
                    name="email"
                    onChange={(event) => this.handleOnChange(event)}
                  />
                </div>

                <button
                  className="log-btn solid login-button"
                  onClick={(event) => this.handleOnSubmit(event)}
                >
                  {this.state.isLoading ? (
                    <Loader type="Oval" color="#222" height={30} width={30} />
                  ) : (
                    "Send"
                  )}
                </button>
              </form>
              <Signup />
            </div>
          </div>

          <div className="panels-container">
            <div className="panel left-panel">
              <div className="content">
                <h3 className="new-heading">New ?</h3>
                <p className="new-heading">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Debitis, ex ratione. Aliquid!
                </p>
                <Link to="/login">
                  <button
                    className="log-btn transparent sign-up-btn"
                    id="sign-in-btn"
                  >
                    Sign in
                  </button>
                </Link>
              </div>
              <img src={image1} className="image" alt="" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ForgotPasswordEmail;
