import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
class CheckuotItem extends Component {
  render() {
    return (
      <div>
        <Link to="/checkout">
          {" "}
          <div className="shopping-cart-icon">
            <div className="absolute-cart">
              <div className="counter">
                <h2>{this.props.cartTotal}</h2>
              </div>
              <i className="fas fa-shopping-cart"></i>
            </div>{" "}
          </div>
        </Link>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cartTotal: state.cart.cartTotal,
  };
}

export default connect(mapStateToProps)(CheckuotItem);
