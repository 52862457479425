import * as actionType from "../../Constants/actiontype";

export const getReusableInit = () => {
  return {
    type: actionType.REUSABLE_INIT,
  };
};

export const getReusableDataSuccess = (data) => {
  return {
    type: actionType.REUSABLE_SUCCESS,
    payload: data,
  };
};

export const getReusableDataFailed = (error) => {
  return {
    type: actionType.REUSABLE_FAILED,
    payload: error,
  };
};
