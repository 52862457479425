import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import "./navbar.css";
import { Link } from "react-router-dom";
import { URL } from "../../Constants/url";
import { signOut } from "../../store/actions/loginaction";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import HeaderLogo from "../../assets/Showcase-Logo.svg";
class MainNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  signOut = () => {
    Cookies.set("Status", "SignedOut");
    this.props.signedOut();
    const { history } = this.props;
    let Token = Cookies.get("Token");
    fetch(`${URL}/signout`, {
      method: "POST",
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: "Bearer " + Token,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (parsedJson) {
        if (parsedJson.message === "success") {
          Cookies.remove("Token");
          Cookies.remove("Name");
          Cookies.remove("Email");
          Cookies.remove("Mobile");
          Cookies.remove("Id");
          if (history) {
            history.push("/");
          }
        }
      })
      .catch(function (error) {
        return error;
      });
  };
  render() {
    return (
      <div>
        <Navbar
          className={
            this.props.navPadding
              ? "main-navbar-cont padding-nav-right"
              : this.props.template
              ? "main-navbar-cont-template main-navbar-cont padding-nav-right"
              : "main-navbar-cont"
          }
          collapseOnSelect
          expand="lg"
          variant="dark"
        >
          <Navbar.Brand>
            <Link to="/">
              <img
                style={{ width: "140px", marginLeft: "10px" }}
                src={HeaderLogo}
                alt="inertiasoft logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="link-style">
              <div>
                <a href="http://showcase.inertiasoft.net/template">
                  <h3>Templates</h3>
                </a>
              </div>
              <div>
                <Link to="/products">
                  <h3>Reusables</h3>
                </Link>
              </div>
              {Cookies.get("Status") === "LoggedIn" &&
              this.state.width > 991 ? (
                <div>
                  <div className="dropdown">
                    <h3 className="dropbtn">
                      {Cookies.get("Name") &&
                      Cookies.get("Name") !== "undefined" ? (
                        Cookies.get("Name")
                      ) : (
                        <Link to="/login">Login</Link>
                      )}
                    </h3>
                    <div className="dropdown-content">
                      <Link
                        className="change-password-link"
                        to="/ChangePassword"
                      >
                        Change Password
                      </Link>
                      <a href="#!" onClick={this.signOut}>
                        Sign out
                      </a>
                    </div>
                  </div>
                </div>
              ) : Cookies.get("Status") === "LoggedIn" &&
                this.state.width < 992 ? (
                <>
                  <div>
                    <Link to="/ChangePassword">
                      <h3>Change Password</h3>
                    </Link>
                  </div>
                  <div>
                    <a href="#!" onClick={this.signOut}>
                      <h3>Sign out</h3>
                    </a>
                  </div>
                </>
              ) : (
                <div>
                  <Link to="/login">
                    <h3>Login</h3>
                  </Link>
                </div>
              )}
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.login.data,
    status: state.login.status,
    snackbaropen: state.login.snackbaropen,
    snackbarmessage: state.login.snackbarmessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signedOut: () => dispatch(signOut()),
  };
}
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MainNavbar);
