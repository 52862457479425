import React, { Component } from "react";
import { URL } from "../../Constants/url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./verifyUser.css";
class Resend extends Component {
  handleOnSubmit = (event) => {
    var that = this;
    event.preventDefault();
    fetch(`${URL}/resend/email/verify`, {
      method: "POST",
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        email: localStorage.getItem("VerfiyEmail"),
      }),
    })
      .then(function (response) {
        that.notify();

        return response.json();
      })
      .catch(function (error) {
        return error;
      });
  };
  notify = (message) => {
    toast.success(message);
  };

  render() {
    return (
      <>
        <div>
          <ToastContainer
            position="bottom-right"
            autoClose={10000}
            newestOnTop={false}
            type="success"
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        <h3>Verification Email has been sent</h3>
        <button
          className="resend-btn solid login-button"
          onClick={this.handleOnSubmit}
        >
          Resend Verification
        </button>
      </>
    );
  }
}

export default Resend;
