import contactForm4Image from "../assets/contact-us-form/images/img-01.png";
import contactForm7FB from "../assets/contact-us-form/images/facebook-01.png";
import contactForm7Insta from "../assets/contact-us-form/images/instagram-01.png";

export const contactValues1 = {
  color: "white",
  backgroundColor: "#301934",
  formHeading: "Reach Us ",
  btnContent: "Send",
  btnColor: "#301934",
  btnBackground: "white",
  nameLabelText: "Username",
  emailLabelText: "Email",
  subjectLabelText: "Subject",
  messageLabelText: "Enter Message",
  labelFontSize: "16px",
  labelFontColor: "#fff",
  inputTextSize: "16px",
  buttonTextSize: "16px",
};

export const contactValues2 = {
  headingBgColor: "#301934",
  addressFontColor: "#black",
  iconBgColor: "#301934",
  btnColor: "white",
  btnBgColor: "#301934",
  headingIconColor: "white",
  headingContent: "Contact Us",
  headingFontSize: "",
  headingFontFamily: "Montserrat",
  headingFontWeight: "",
  headingColor: "white",
  tagLine: "We'll write rarely, but only the best content.",
  tagLineFontSize: "20px",
  mapHeadingFontSize: "",
  mapHeadingFontFamily: "Montserrat",
  mapHeadingFontWeight: "bold",
  mapHeadingFontColor: "black",
  btnContent: "Send",
  nameLabelText: "Name",
  emailLabelText: "Email",
  subjectLabelText: "Contact No.",
  messageLabelText: "Enter Message",
  addressText: "New York, 94126",
  countryText: "United States",
  number: "+001-1234567",
  timings: "09:00 - 18:00",
  email: "contact@lorem.com",
  email2: "contact-2@lorem.com",
  map_src:
    "https://maps.google.com/maps?width=100%25&height=600&hl=en&q=New+York,+NY,+USA/@40.6976701,-74.2598737,10z&t=&z=14&ie=UTF8&iwloc=B&output=embed",
  map_title: "Lorem ipsum ...., ",
  map_aria_label: "Lorem ipsum ...., ",
  labelFontSize: "16px",
  inputTextSize: "16px",
  buttonFontSize: "16px",
  buttonFontWeight: "bolder",
  buttonFontFamily: "Montserrat",
  buttonWidth: "150px",
  buttonHeight: "50px",
};

export const contactValues3 = {
  namePlaceholder: "Name",
  emailPlaceholder: "Email",
  numberPlaceholder: "Contact No.",
  messagePlaceholder: "Message",
  btnText: "Submit",
  btnFontSize: "16px",
  btnWidth: "160px",
  btnHeight: "50px",
  content: [
    {
      title: "FIND US AT",
      icon: "fa fa-map-marker",
      description:
        "30 Glance street Sector Beijing, China",
    },
    {
      title: "GENERAL ENQUIRIES",
      icon: "fa fa-paperclip",
      description: "Info@restaurant.com",
    },
    {
      title: "CALL US",
      icon: "fa fa-mobile-phone",
      description: "11:00 AM TO 02:00 AM",
    },
    {
      title: "OPERATION HOURS",
      icon: "fa fa-clock-o",
      description: "11:00 AM TO 02:00 AM",
    },
  ],
};

export const contactValues4 = {
  imgSrc: contactForm4Image,
  heading: "Contact Us",
  btnContent: "Send",
  namePlaceholder: "Name",
  emailPlaceholder: "Email",
  subjectPlaceholder: "Subject",
  messagePlaceholder: "Message",
};

export const contactValues5 = {
  backgroundImage: "../assets/contact-us-form/images/img-01.png",
  heading: "Contact Us",
  btnContent: "Send",
  nameLabelText: "Name",
  emailLabelText: "Email",
  messageLabelText: "Message",
  background:
    "linear-gradient(45deg,rgba(213, 0, 125, 0.8),rgba(229, 57, 53, 0.8))",
};

export const contactValues7 = {
  title: ["CONNECT WITH", " US"],
  contactDetails: {
    contactNo: " +12 345 678 910 ",
    email: " info@demo.com ",
  },
  socialIcons: [
    {
      imageSrc: contactForm7FB,
      href: "www.facebook.com",
    },
    {
      imageSrc: contactForm7Insta,
      href: "www.instagram.com",
    },
  ],
  subTextsize: "16px",
  inputTextSize: "16px",
  textColor: "#ffff",
  buttonText: "Submit",
  buttonTextSize: "16px",
  buttonBackgroundColor: "#a3150",
  backgroundImageSrc: "",
  backgroundColor: "black",
  namePlaceholder: "Name",
  messagePlaceholder: "Message",
  emailPlaceholder: "Email",
  contactPlaceholder: "Contact No.",
  subHeading: "Like to hear from our Customers..",
};

export const contactValues8 = {
  namePlaceholder: "Name",
  messagePlaceholder: "Message",
  emailPlaceholder: "Email",
  backgroundColor: "transparent",
  btnBackground: "rgb(104, 120, 214)",
  btnContent: "Send",
  map_src:
    "https://maps.google.com/maps?width=100%25&height=600&hl=en&q=New+York,+NY,+USA/@40.6976701,-74.2598737,10z&t=&z=14&ie=UTF8&iwloc=B&output=embed",
  map_title: "Lorem ipsum ...., ",
  map_aria_label: "Lorem ipsum ...., ",
  inputTextSize: "16px",
  buttonTextSize: "16px",
  buttonWidth: "150px",
};

export const contactValues9 = {
  heading: "Get in Touch",
  labelName: "Name",
  labelEmail: "Email",
  labelSubject: "Contact Number",
  labelMessage: "Message",
  btnContent: "Send",
  labelFontSize: "14px",
  inputTextSize: "16px",
};
