import "./home.css";
import React from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import * as VanillaTilt from "vanilla-tilt";
import { TweenMax } from "gsap";
import Nav from "../../commons/Nav/nav";
import Navbar from "../../commons/Navbar/navbar";
import ContactDetails from "../../commons/Contact/contactDetails";
import { getReusablesHomeData } from "../../sources/reusablehomedatasource";
import { connect } from "react-redux";
import CheckuotItem from "../CheckoutItem/checkuotItem";

class App extends React.Component {
  state = {
    index: 0,
  };
  componentDidMount() {
    this.props.getReusablesHomeData(8);
    var element = document.getElementById("fullpage");
    element.classList.add("mystyle");
    VanillaTilt.init(this.rootNode, {
      max: 1,
      speed: 2000,
      scale: 1,
    });

    VanillaTilt.init(this.child_first, {
      max: 1,
      speed: 2000,
      scale: 1,
    });

    VanillaTilt.init(this.child_second, {
      max: 1,
      speed: 2000,
      scale: 1,
    });
    VanillaTilt.init(this.child_third, {
      max: 1,
      speed: 2000,
      scale: 1,
    });
    VanillaTilt.init(this.child_fourth, {
      max: 1,
      speed: 2000,
      scale: 1,
    });
    VanillaTilt.init(this.child_fifth, {
      max: 1,
      speed: 2000,
      scale: 1,
    });
    VanillaTilt.init(this.child_sixth, {
      max: 1,
      speed: 2000,
      scale: 1,
    });
    VanillaTilt.init(this.child_seventh, {
      max: 1,
      speed: 2000,
      scale: 1,
    });
  }

  componentWillUnmount() {
    window.fullpage_api.setAllowScrolling(false);
  }

  onLeave(origin, destination, direction) {
    TweenMax.to(".overlay-bg", 0.3, {
      width: "100%",
    });
    TweenMax.to(".bg", 0.3, {
      x: "-50%",
      y: "-50%",
    });
    TweenMax.to(".over-1", 0.5, {
      height: "100%",
    });
    TweenMax.to(".over-2", 0.5, {
      height: "100%",
      delay: "0.1",
    });
    TweenMax.to(".over-3", 0.5, {
      height: "100%",
      delay: "0.2",
    });
    TweenMax.to(".over-4", 0.5, {
      height: "100%",
      delay: "0.3",
    });
    TweenMax.to(".over-5", 0.5, {
      height: "100%",
      delay: "0.4",
    });
  }
  afterLoad(origin, destination, direction) {
    // window.fullpage_api.moveSectionUp();

    this.setState({ index: destination.index });

    TweenMax.to(".overlay-bg", 1, {
      width: "0",
      delay: "1",
    });
    TweenMax.to(".bg", 0.4, {
      scale: "1",
      x: "-50%",
      y: "-50%",
      skewX: "0",
      skewY: "0",
    });
    TweenMax.to(".over-1", 0.5, {
      height: 0,
    });
    TweenMax.to(".over-2", 0.5, {
      height: 0,
      delay: "0.1",
    });
    TweenMax.to(".over-3", 0.5, {
      height: "0",
      delay: "0.2",
    });
    TweenMax.to(".over-4", 0.5, {
      height: 0,
      delay: "0.3",
    });

    TweenMax.to(".over-5", 0.5, {
      height: 0,
      delay: "0.4",
    });
  }

  render() {
    return (
      <>
        <div></div>
        <div>
          <ContactDetails />
          <Navbar template={true} quantity={localStorage.getItem("total")} />
          <CheckuotItem />
          <Nav
            data={this.props.reuseabledata ? this.props.reuseabledata.data : ""}
            destination={this.state.index}
          />
          <div className="c-scroll-icon">
            <p>SCROLL</p>
            <div className="c-scroll-icon-line-mask">
              <div className="c-scroll-icon-line" />
            </div>
            <div className="c-scroll-icon-triangle">
              <div className="c-scroll-icon-triangle-mask first">
                <div className="c-scroll-icon-triangle-line first" />
              </div>
              <div className="c-scroll-icon-triangle-mask right">
                <div className="c-scroll-icon-triangle-line right" />
              </div>
              <div className="c-scroll-icon-triangle-mask left">
                <div className="c-scroll-icon-triangle-line left" />
              </div>
              <div className="c-scroll-icon-triangle-mask last">
                <div className="c-scroll-icon-triangle-line last" />
              </div>
            </div>
          </div>
          <div className="border-fixed" />
          <ReactFullpage
            navigation={true}
            scrollingSpeed={2000}
            onLeave={this.onLeave.bind(this)}
            afterLoad={this.afterLoad.bind(this)}
            render={({ state, fullpageApi }) => {
              return (
                <>
                  <div id="fullpage-wrapper">
                    {this.props.reuseabledata ? (
                      this.props.reuseabledata.data.map((items, index) => {
                        return (
                          <div className="section" key={index}>
                            <div>
                              <div className="bg">
                                <div className="overlay-bg" />
                                <div
                                  ref={(node) =>
                                    index === 0
                                      ? (this.rootNode = node)
                                      : index === 1
                                      ? (this.child_first = node)
                                      : index === 2
                                      ? (this.child_second = node)
                                      : index === 3
                                      ? (this.child_third = node)
                                      : index === 4
                                      ? (this.child_fourth = node)
                                      : index === 5
                                      ? (this.child_fifth = node)
                                      : index === 6
                                      ? (this.child_sixth = node)
                                      : index === 7
                                      ? (this.child_seventh = node)
                                      : ""
                                  }
                                  className={`project-img project-${index}`}
                                />
                              </div>

                              <div className="overlay-swipe">
                                <div className="over-1" />
                                <div className="over-2" />
                                <div className="over-3" />
                                <div className="over-4" />
                                <div className="over-5" />
                                <div className="over-1-border" />
                                <div className="over-2-border" />
                                <div className="over-3-border" />
                                <div className="over-4-border" />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="section">Loading</div>
                    )}
                  </div>
                </>
              );
            }}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.reusable.data,
    reuseabledata: state.reusablehomedata.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getReusablesHomeData: (id) => dispatch(getReusablesHomeData(id)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
