import * as actionType from "../../Constants/actiontype";

const initialState = {
  data: null,
  snackbaropen: false,
  snackbarmessage: "",
};

export default function loginreducer(state = initialState, action) {
  switch (action.type) {
    case actionType.LOGIN_INIT:
      return {
        ...state,
        isLoading: true,
      };
    case actionType.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: action.status,
      };
    case actionType.LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        data: action.error,
      };
    case actionType.LOGIN_SNACKBAR_OPEN:
      return {
        ...state,
        snackbaropen: true,
        snackbarmessage: "Username or Password is incorrect",
      };
    case actionType.LOGIN_SNACKBAR_CLOSE:
      return {
        ...state,
        snackbaropen: false,
      };
    case actionType.SIGNOUT:
      return {
        ...state,
        status: "Signout",
        snackbaropen: false,
      };
    default:
      return state;
  }
}
