import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home/home";
import LandingPage from "./components/LandingPage/landingPage";
import "bootstrap/dist/css/bootstrap.min.css";
import login from "./components/Login/login";
import checkout from "./components/Checkout/checkout";
import Preview from "./components/ComponentPreview/componentPreview";
import Reuseable from "./components/Reuseable/reuseable";
import ForgotPassword from "./components/ForgotPassword/forgotPassword";
import ChangePassword from "./components/ChangePassword/changePassword";
import Reus2 from "./components/Reus2/Reus2";
import Confirmation from "./components/Confirmation/confirmation";
import ReusableComponents from "./components/ReusableComponents/reusableComponents";
import ForgotPasswordEmail from "./components/ForgotPasswordEmail/forgotPasswordEmail";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Verify from "./components/Verify/verify";
import ScrollToTop from "./components/ScrollToTop";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/preview" component={Reuseable} />
        <Route exact path="/forgot" component={ForgotPasswordEmail} />
        <Route exact path="/products" component={Reus2} />
        <Route exact path="/changePassword" component={ChangePassword} />
        <Route path="/checkout" component={checkout} />
        <Route path="/reusable" component={Preview} />
        <Route exact path="/reusables" component={ReusableComponents} />
        <Route path="/login" component={login} />
        <Route path="/template" component={Home} />
        <Route path="/verify" component={Verify} />
        <Route path="/reset" component={ForgotPassword} />
        <Route path="/confirmation" component={Confirmation} />
        <ScrollToTop path="/" component={LandingPage} />
      </Switch>
    </Router>
  );
}
