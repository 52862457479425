export const checkoutValues1 = {
  title: "Billing Details",
  checkoutButtonText: "PROCEED TO CHECKOUT",
  checkoutButtonBackgroundColor: "rgb(34, 34, 34)",
  checkoutButtonTextColor: "rgb(255, 255, 255)",
  checkoutButtonTextSize: "16px",
  checkoutButtonTextFamily: `'Circular Std Book', Helvetica, Arial, 'Lucida Grande',sans-serif`,
  checkoutButtonTextWeight: "bold",
  subTotal: "$90000",
  shipping: "Flat Rate: 7.50",
  total: "9700",
};
