import axios from "axios";
import {
  getReusablesInit,
  getReusablesDataSuccess,
  getReusablesDataFailed,
} from "../store/actions/reusablehomedataaction";
import { URL } from "../Constants/url";

export const getReusablesHomeData = (id) => {
  return (dispatch) => {
    dispatch(getReusablesInit());
    axios
      .get(`${URL}/product/variants/?productId=${id}`)
      .then((response) => {
        dispatch(getReusablesDataSuccess(response));
      })
      .catch((error) => {
        dispatch(getReusablesDataFailed(error));
      });
  };
};
