export const careerValues1 = {
  buttonText: "Apply Now",
  jobTitles: ["Manager", "Chef"],
  button: {
    borderColor: "#D88F50",
    width: "150px",
  },
};

export const careerValues2 = {
  formTitle1: "Job",
  formTitle2: "Application",
  textColor: "#ffff",
  buttonText: "Apply Now",
  buttonBackgroundColor: "#a3150",
  backgroundImageSrc: "",
  backgroundColor: "black",
  namePlaceholder: "Name",
  numberPlaceholder: "Contact No.",
  messagePlaceholder: "Message",
  emailPlaceholder: "Email",
  subHeading: "",
  jobTitles: ["Manager", "Chef"],
  inputTextSize: "16px",
  buttonTextSize: "16px",
};

export const careerValues3 = {
  color: "white",
  backgroundColor: "#301934",
  formHeading: "Job Application",
  btnContent: "Apply Now",
  btnColor: "#301934",
  btnBackground: "white",
  nameLabelText: "Name",
  numberLabelText: "Number",
  emailLabelText: "Email",
  designationLabelText: "Job Title",
  fileLabelText: "Attach CV",
  jobTitles: ["Manager", "Chef"],
  labelFontSize: "16px",
  labelFontColor: "#fff",
  inputTextSize: "16px",
  buttonTextSize: "16px",
};
