import Logo from "../assets/logo.png";
import menu_bar_image from "../assets/side-bar/images/right-menu-bars.png";
import percentage from "../assets/side-bar/images/percentage.png";
import joyStick from "../assets/side-bar/images/joystick.png";
import mobilePhone from "../assets/side-bar/images/mobile-phone.png";
import pcTower from "../assets/side-bar/images/pc-tower.png";
import washer from "../assets/side-bar/images/washer.png";
import microphone from "../assets/side-bar/images/microphone.png";
import highVolume from "../assets/side-bar/images/high-volume.png";
import _return_ from "../assets/side-bar/images/return.png";
import sidebar5Logo from "../assets/side-bar/images/logo.png";

export const sidebarValues1 = {
  bodyBackgroundColor: "#fff",
  zIndex: "1",
  items: [
    {
      name: "Home",
    },
    {
      name: "Contact",
    },
    {
      name: "Services",
    },
    {
      name: "Blogs",
    },
    {
      name: "News",
    },
    {
      name: "Donate",
    },
  ],
  backgroundColor: "#222",
  burgerIconColor: "#fff",
  itemListColor: "#fff",
  itemListFontSize: "16px",
  itemListFontFamily: "Open Sans, sans-serif",
  itemListFontWeight: "",
};

export const sidebarValues2 = {
  bodyBackgroundColor: "#fff",
  zIndex: "999",
  logo: "/assets/images/sidebar_2_logo.webp",
  logoWidth: "120px",
  sideBarItems: [
    {
      image:
        "https://colorlib.com/preview/theme/steakshop/img/header/nav-icon1.png",
      name: "home",
      href: "#",
      subItems: [],
    },
    {
      image:
        "https://colorlib.com/preview/theme/steakshop/img/header/nav-icon2.png",
      name: "about",
      href: "#",
      subItems: [],
    },
    {
      image:
        "https://colorlib.com/preview/theme/steakshop/img/header/nav-icon3.png",
      name: "menu",
      href: "#",
      subItems: [],
    },
    {
      image:
        "https://colorlib.com/preview/theme/steakshop/img/header/nav-icon4.png",
      name: "Book Table",
      href: "#",
      subItems: [],
    },
    {
      image:
        "https://colorlib.com/preview/theme/steakshop/img/header/nav-icon5.png",
      name: "chefs",
      href: "#",
      subItems: [],
    },
    {
      image:
        "https://colorlib.com/preview/theme/steakshop/img/header/nav-icon6.png",
      name: "pages",
      href: "#",
      subItems: [
        {
          image: "",
          name: "element",
          href: "#",
        },
      ],
    },
    {
      image:
        "https://colorlib.com/preview/theme/steakshop/img/header/nav-icon7.png",
      name: "blog",
      href: "#",
      subItems: [
        {
          image: "",
          name: "blog",
          href: "#",
        },
        {
          image: "",
          name: "blog details",
          href: "#",
        },
      ],
    },
    {
      image:
        "https://colorlib.com/preview/theme/steakshop/img/header/nav-icon8.png",
      name: "contact",
      href: "#",
      subItems: [],
    },
  ],
  listItemFontSize: "",
  listItemFontFamily: "",
  listItemFontColor: "",
  listItemFontWeight: "",
};

export const sidebarValues3 = {
  bodyBackgroundColor: "#222",
  zIndex: "100",
  logo: Logo,
  logoWidth: "120px",
  miniLogo: Logo,
  miniLogoWidth: "80px",
  sideBarItems: [
    {
      name: "home",
      href: "#",
      icon: "fa fa-home",
      subMenuItems: [],
    },
    {
      name: "about",
      href: "#",
      icon: "fa fa-user",
      subMenuItems: [],
    },
    {
      name: "entries",
      href: "#",
      icon: "fa fa-pencil",
      subMenuItems: [],
    },
    {
      name: "work",
      href: "#",
      icon: "fa fa-image",
      subMenuItems: [],
    },
    {
      name: "contact",
      href: "#",
      icon: "fa fa-envelope",
      subMenuItems: [],
    },
  ],
  listItemFontSize: "",
  listItemFontFamily: "",
  listItemFontColor: "",
  listItemFontWeight: "",
};

export const sidebarValues4 = {
  bodyBackgroundColor: "#fff",
  zIndex: "1",
  sideBarItems: [
    {
      name: "home",
      href: "#",
    },
    {
      name: "work",
      href: "#",
    },
    {
      name: "about",
      href: "#",
    },
    {
      name: "services",
      href: "#",
    },
    {
      name: "blog",
      href: "#",
    },
    {
      name: "contact",
      href: "#",
    },
  ],
  navbarFooter: {
    socialIcons: [
      {
        name: "fa fa-facebook",
        link: "www.facebook.com",
      },
      {
        name: "fa fa-twitter",
        link: "www.twitter.com",
      },
      {
        name: "fa fa-instagram",
        link: "www.instagram.com",
      },
      {
        name: "fa fa-linkedin",
        link: "www.linkedin.com",
      },
    ],
    copyRightContent: "Powered By",
    copyRightHref: "http://inertiasoft.net",
    copyRightCompany: " InertiaSoft"
  },
  listItemFontSize: "",
  listItemFontFamily: "",
  listItemFontColor: "",
  listItemFontWeight: "",
};

export const sidebarValues5 = {
  bodyBackgroundColor: "#fff",
  zIndex: "1",
  logo: {
    image: sidebar5Logo,
    href: "#",
    width: "120px",
  },
  sideBarItems: [
    {
      name: "home",
      href: "#",
      subItems: [
        {
          name: "home1",
          href: "#",
        },
        {
          name: "home2",
          href: "#",
        },
        {
          name: "home3",
          href: "#",
        },
      ],
    },
    {
      name: "about",
      href: "#",
      subItems: [],
    },
    {
      name: "pages",
      href: "#",
      subItems: [
        {
          name: "pages1",
          href: "#",
        },
        {
          name: "pages2",
          href: "#",
        },
        {
          name: "pages3",
          href: "#",
        },
      ],
    },
    {
      name: "portfolio",
      href: "#",
      subItems: [],
    },
    {
      name: "contact",
      href: "#",
      subItems: [],
    },
  ],
  copyRighSection: {
    content: "Powered By",
    href: "http://inertiasoft.net",
    company: " InertiaSoft"
  },
  listItemFontSize: "16px",
  listItemFontFamily: "",
  listItemFontColor: "#fff",
  listItemFontWeight: "",
};

export const sidebarValues6 = {
  bodyBackgroundColor: "#fff",
  menu_bar_image,
  zIndex: "1",
  selectTitle: "Navigation",
  listItem: [
    {
      href: "#",
      image: percentage,
      name: "On Sale!",
      subList: {
        backgroundImage: "",
        items: [],
      },
    },
    {
      href: "#",
      image: joyStick,
      name: "Music & Games",
      subList: {
        backgroundImage: "",
        items: [],
      },
    },
    {
      href: "#",
      image: mobilePhone,
      name: "Smart Phones",
      subList: {
        backgroundImage: "",
        items: [],
      },
    },
    {
      href: "#",
      image: pcTower,
      name: "PC & Desktop",
      subList: {
        backgroundImage: "",
        items: [],
      },
    },
    {
      href: "#",
      image: washer,
      name: "Home Appliances",
      subList: {
        backgroundImage: "",
        items: [],
      },
    },
    {
      href: "#",
      image: microphone,
      name: "Accessories",
      subList: {
        backgroundImage: "",
        items: [],
      },
    },
    {
      href: "#",
      image: highVolume,
      name: "Audios",
      subList: {
        backgroundImage: "",
        items: [],
      },
    },
    {
      href: "#",
      image: _return_,
      name: "Gift Cards",
      subList: {
        backgroundImage: "",
        items: [],
      },
    },
  ],
  listItemFontSize: "",
  listItemFontFamily: "",
  listItemFontColor: "",
  listItemFontWeight: "",
};

export const sidebarValues7 = {
  bodyBackgroundColor: "#222",
  zIndex: "1",
  listItem: [
    {
      name: "Home",
      href: "",
    },
    {
      name: "Works",
      href: "",
    },
    {
      name: "News",
      href: "",
    },
    {
      name: "Studio",
      href: "",
    },
    {
      name: "Contact",
      href: "",
    },
  ],
  languages: [
    {
      name: "Eng",
      href: "",
    },
    {
      name: "Fra",
      href: "",
    },
    {
      name: "Ger",
      href: "",
    },
  ],
  footerYear: "© 2021",
  footerRights: "Powered by",
  footerName: " ",
  footerDesgin: " ",
  footerCompany: "InertiaSoft",
  listItemFontSize: "25px",
  listItemFontFamily: "",
  listItemFontColor: "",
  listItemFontWeight: "",
};
