import * as actionType from "../../Constants/actiontype";

export const getReusablesInit = () => {
  return {
    type: actionType.REUSABLE_HOME_INIT,
  };
};

export const getReusablesDataSuccess = (data) => {
  return {
    type: actionType.REUSABLE_HOME_SUCCESS,
    payload: data,
  };
};

export const getReusablesDataFailed = (error) => {
  return {
    type: actionType.REUSABLE_HOME_FAILED,
    payload: error,
  };
};
