export const breadcrumbValue1 = {
  item1: {
    name: "Your Items",
    items: {
      details: "Items",
    },
  },
  item2: {
    name: "Your Details",
    items: {
      details: "Details",
    },
  },
  item3: {
    name: "Preview Order",
    items: {
      details: "Preview",
    },
  },
  item4: {
    name: "Send Order",
    items: {
      details: "Send",
    },
  },
};

export const breadcrumbValue2 = {
  item1: "Items in order",
  item2: "Customer information",
  item3: "Shipping",
};

export const breadcrumbValue3 = {
  item1: "Home",
  item2: "Gallery",
  item3: "Web",
  item4: "Project",
  color: "#96c03d",
};

export const breadcrumbValue4 = {
  item1: "Home",
  item2: "Gallery",
  item3: "Web",
  item4: "Project",
  color: "#96c03d",
};

export const breadcrumbValue5 = {
  item1: "Home",
  item2: "Gallery",
  item3: "Web",
  item4: "Project",
  color: "#96c03d",
};

export const breadcrumbValue6 = {
  item1: "Home",
  item2: "Gallery",
  item3: "Web",
  item4: "Project",
  color: "#96c03d",
};

export const breadcrumbValue7 = {
  item1: "Home",
  item2: "Gallery",
  item3: "Web",
  item4: "Project",
  color: "#96c03d",
};

export const breadcrumbValue8 = {
  item1: "Cart",
  item2: "Billing",
  item3: "Delivey",
  item4: "Review",
  color: "#96c03d",
};

export const breadcrumbValue9 = {
  item1: "Cart",
  item2: "Billing",
  item3: "Delivey",
  item4: "Review",
  color: "#96c03d",
};

export const breadcrumbValue10 = {
  item1: "Cart",
  item2: "Billing",
  item3: "Delivey",
  item4: "Review",
};

export const breadcrumbValue11 = {
  item1: "Cart",
  item2: "Billing",
  item3: "Delivey",
  item4: "Review",
};

export const breadcrumbValue12 = {
  item1: "Back",
  item2: "Link 1",
  item3: "Link 2",
  item4: "Link 3",
  item5: "Link",
  item6: "Privacy Policy",
  item7: "Support / Help",
};
